import axios from 'axios'

axios.defaults.withCredentials = true

const defaultTimeout = 10000 //10 seconds

export const get = (url: string, headers: any, timeout?: number) => {
  if (!timeout) {
    timeout = defaultTimeout
  }
  return axios.get(url, {
    headers: { ...headers },
    timeout: timeout,
  })
}