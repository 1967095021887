import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";

type Props = {
  date: Date | null;
  setDate: (date: Date | null) => void;
  wrongDate: boolean;
};

export default function Datum(props: Props) {
  return (
    <div className="sectionContainer">
      <div className="sectionTitleContainer">Datum</div>
      <div className="dateContainer">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label="Fakturadatum"
            value={props.date}
            onChange={(date) => props.setDate(date)}
            renderInput={(params) => <TextField {...params} />}
            className="datePicker"
          />
        </LocalizationProvider>
      </div>
      <p className="helperText">
        {props.wrongDate
          ? "Varning, du har nu valt ett datum där Domstools saknar uppgifter om timkostnadsnorm och taxa. Beräkningarna utgår nu från det innevarande årets värden."
          : undefined}
      </p>
    </div>
  );
}
