import React, { useEffect, useState } from "react";
import {
  numToString,
  roundTo2Decimals,
} from "../../../services/commonFunctions";
type Props = {
  sumArbetadTid: number;
  tidsspillanSum: number;
  utlaggSum: number;
  summaExMoms: number;
  summaExMomsRounded: number;
};
export default function Sammanstallning(props: Props) {
  const [summaExMomsRounded, setSummaExMomsRounded] = useState(
    props.summaExMomsRounded
  );
  const [momsRounded, setMomsRounded] = useState(
    Math.round(props.summaExMomsRounded * 0.25)
  );
  const [summaIncMomsRounded, setSummaIncMomsRounded] = useState(
    summaExMomsRounded + momsRounded
  );

  useEffect(() => {
    setSummaExMomsRounded(props.summaExMomsRounded);
    setMomsRounded(Math.round(props.summaExMomsRounded * 0.25));
    setSummaIncMomsRounded(
      Math.round(props.summaExMomsRounded) +
        Math.round(props.summaExMomsRounded * 0.25)
    );
  }, [props.summaExMoms, props.summaExMomsRounded]);
  return (
    <div className="sectionContainer">
      <div className="sectionTitleContainer">Sammanställning</div>
      <div className="listContainer">
        {props.sumArbetadTid > 0 ? (
          <div className="sumListObjectContainer">
            <p className="sumListText" id="sumListText-0">
              Arbete:
            </p>
            <p
              className="sumListNonRoundedNumber"
              id="sumListNonRoundedNumber-0"
            >
              ({numToString(roundTo2Decimals(props.sumArbetadTid))})<sup>*</sup>
            </p>
            <p className="sumListNumber" id="sumListNumber-0">
              {numToString(Math.round(props.sumArbetadTid))} Kr
            </p>
          </div>
        ) : null}
        {props.tidsspillanSum > 0 ? (
          <div className="sumListObjectContainer">
            <p className="sumListText">Tidsspillan:</p>
            <p className="sumListNonRoundedNumber">
              ({numToString(roundTo2Decimals(props.tidsspillanSum))})
              <sup>*</sup>
            </p>
            <p className="sumListNumber">
              {numToString(Math.round(props.tidsspillanSum))} Kr
            </p>
          </div>
        ) : null}
        {props.utlaggSum > 0 ? (
          <div className="sumListObjectContainer">
            <p className="sumListText">Utlägg:</p>
            <p className="sumListNonRoundedNumber">
              ({numToString(roundTo2Decimals(props.utlaggSum))})<sup>*</sup>
            </p>
            <p className="sumListNumber">
              {numToString(Math.round(props.utlaggSum))} Kr
            </p>
          </div>
        ) : null}
        <div className="sumListObjectContainer">
          <p className="sumListText">Summa ex moms:</p>
          <p className="sumListNonRoundedNumber bold">
            ({numToString(roundTo2Decimals(props.summaExMoms))})<sup>*</sup>
          </p>
          <p className="sumListNumber semifinal">
            {numToString(summaExMomsRounded)} Kr
          </p>
        </div>
        <div className="sumListObjectContainer">
          <p className="sumListText">Moms:</p>
          <p className="sumListNonRoundedNumber">
            ({numToString(roundTo2Decimals(props.summaExMoms * 0.25))})
            <sup>*</sup>
          </p>
          <p className="sumListNumber">{numToString(momsRounded)} Kr</p>
        </div>
        <div className="sumListObjectContainer">
          <p className="sumListText">Summa ink moms:</p>
          <p className="sumListNonRoundedNumber bold">
            ({numToString(roundTo2Decimals(props.summaExMoms * 1.25))})
            <sup>*</sup>
          </p>
          <p className="sumListNumber final">
            {numToString(summaIncMomsRounded)} Kr
          </p>
        </div>
      </div>
      <div className="extraDetailsContainer">
        <p className="extraDetailsText">
          <sup>*</sup>Belopp innan avrundning
        </p>
      </div>
    </div>
  );
}
