export class FakturaInformationInfo {
  fakturaNummer: string;
  advokatbyra: string;
  advokatsNamn: string;
  adress: string;
  postnummer: string;
  ort: string;
  telefon: string;
  epost: string;
  orgNummer: string;
  bankgiro: string;

  constructor(
    fakturaNummer: string,
    advokatbyra: string,
    advokatsNamn: string,
    adress: string,
    postnummer: string,
    ort: string,
    telefon: string,
    epost: string,
    orgNummer: string,
    bankgiro: string
  ) {
    this.fakturaNummer = fakturaNummer;
    this.advokatbyra = advokatbyra;
    this.advokatsNamn = advokatsNamn;
    this.adress = adress;
    this.postnummer = postnummer;
    this.ort = ort;
    this.telefon = telefon;
    this.epost = epost;
    this.orgNummer = orgNummer;
    this.bankgiro = bankgiro;
  }

  static DefaultFakturaInforamtionInfo() {
    let tempDefaultFakturaInforamtionInfo = new FakturaInformationInfo(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );

    return tempDefaultFakturaInforamtionInfo;
  }
}
