export class ArbetadTidInfo {
  hours: number;
  minutes: number;
  helgdagHours: number;
  helgdagMinutes: number;
  taxemal: boolean;
  haktningsforhandling: boolean;
  rpu: boolean;

  constructor(
    hours: number,
    minutes: number,
    helgdagHours: number,
    helgdagMinutes: number,
    taxemal: boolean,
    haktningsforhandling: boolean,
    rpu: boolean
  ) {
    this.hours = hours;
    this.minutes = minutes;
    this.helgdagHours = helgdagHours;
    this.helgdagMinutes = helgdagMinutes;
    this.taxemal = taxemal;
    this.haktningsforhandling = haktningsforhandling;
    this.rpu = rpu;
  }

  static DefaultArbetadTidInfo() {
    let tempDefaultArbetadTidInfo = new ArbetadTidInfo(
      0,
      0,
      0,
      0,
      false,
      false,
      false
    );

    return tempDefaultArbetadTidInfo;
  }
}
