import { cloneDeep } from "lodash";
import { DateTime } from "luxon";
import {
  ADRESS_IN_FAKTURA_INFORMATION_INFO_POSITION,
  ADVOKATBYRA_IN_FAKTURA_INFORMATION_INFO_POSITION,
  ADVOKATS_NAMN_IN_FAKTURA_INFORMATION_INFO_POSITION,
  ARBETAD_TID_INFO_POSITION,
  BANKGIRO_IN_FAKTURA_INFORMATION_INFO_POSITION,
  DATE_POSITION,
  DOMSTOL_IN_INFORMATION_OM_MALET_INFO_POSITION,
  EPOST_IN_FAKTURA_INFORMATION_INFO_POSITION,
  EXTRA_EXPENSES_IN_UTLAGG_INFO_POSITION,
  FAKTURA_INFORMATION_INFO_POSITION,
  FAKTURA_NUMMER_IN_FAKTURA_INFORMATION_INFO_POSITION,
  HAKTNINGSFORHANDLING_IN_ARBETAD_TID_INFO_POSITION,
  HELGDAG_MINUTES_IN_ARBETAD_TID_INFO_POSITION,
  INFORMATION_OM_MALET_INFO_POSITION,
  KILOMETERS_IN_UTLAGG_INFO_POSITION,
  KLIENT_IN_INFORMATION_OM_MALET_INFO_POSITION,
  MALNUMMER_IN_INFORMATION_OM_MALET_INFO_POSITION,
  MINUTES_IN_ARBETAD_TID_INFO_POSITION,
  ORG_NUMMER_IN_FAKTURA_INFORMATION_INFO_POSITION,
  ORT_IN_FAKTURA_INFORMATION_INFO_POSITION,
  OVRIG_TID_MINUTES_IN_TIDSSPILLAN_INFO_POSITION,
  POSTNUMMER_IN_FAKTURA_INFORMATION_INFO_POSITION,
  RPU_IN_ARBETAD_TID_INFO_POSITION,
  TAXEMAL_IN_ARBETAD_TID_INFO_POSITION,
  TELEFON_IN_FAKTURA_INFORMATION_INFO_POSITION,
  TIDSSPILLAN_INFO_POSITION,
  UTLAGG_INFO_POSITION,
  VARDAGAR_MINUTES_IN_TIDSSPILLAN_INFO_POSITION,
} from "./constants";

export function pdfDate(date: string) {
  return DateTime.fromISO(date).toFormat("yyyy-LL-dd");
}

export function currentDateAndTime(date: string) {
  return DateTime.fromISO(date).toFormat("yyyy-LL-dd HH:mm:ss");
}

export function findTodaysDate() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  let todayString = yyyy + "/" + mm + "/" + dd;
  return todayString;
}
export function deepCloneObject(object: any): any {
  return cloneDeep(object);
}

export const numToString = (value: number) => {
  const stringNum = value.toString();
  let thousandPart = stringNum.split(".")[0];
  let decimaPart = "";
  if (stringNum.includes(".")) {
    decimaPart = stringNum.split(".")[1];
  }

  if (thousandPart.length > 3) {
    thousandPart = thousandPart
      .split("")
      .reverse()
      .join("")
      .match(/.{1,3}/g)!
      .map(function (x: string) {
        return x.split("").reverse().join("");
      })
      .reverse()
      .join(" ");
  }

  if (decimaPart === "") {
    return thousandPart;
  } else {
    return [thousandPart, decimaPart].join(",");
  }
};

export function calculateMinutes(hours: number, minutes: number) {
  return hours * 60 + minutes;
}

export function getHoursFromMinutes(minutes: number) {
  return { hours: parseInt((minutes / 60).toString()), minutes: minutes % 60 };
}

export function linkObjectToArray(object: any) {
  const tempArray = [];
  tempArray.push(object.date);

  const tempArbetadTidInfoArray = [];
  tempArbetadTidInfoArray.push(
    calculateMinutes(object.arbetadTidInfo.hours, object.arbetadTidInfo.minutes)
  );
  tempArbetadTidInfoArray.push(
    calculateMinutes(
      object.arbetadTidInfo.helgdagHours,
      object.arbetadTidInfo.helgdagMinutes
    )
  );
  tempArbetadTidInfoArray.push(object.arbetadTidInfo.taxemal);
  tempArbetadTidInfoArray.push(object.arbetadTidInfo.haktningsforhandling);
  tempArbetadTidInfoArray.push(object.arbetadTidInfo.rpu);

  tempArray.push(tempArbetadTidInfoArray);

  const tempTidsspillanInfoArray = [];
  tempTidsspillanInfoArray.push(
    calculateMinutes(
      object.tidsspillanInfo.vardagarHours,
      object.tidsspillanInfo.vardagarMinutes
    )
  );
  tempTidsspillanInfoArray.push(
    calculateMinutes(
      object.tidsspillanInfo.ovrigTidHours,
      object.tidsspillanInfo.ovrigTidMinutes
    )
  );
  tempArray.push(tempTidsspillanInfoArray);

  const tempUtlaggInfoArray = [];
  tempUtlaggInfoArray.push(object.utlaggInfo.kilometers);
  let tempExtraExpensesArray = [];
  for (let extraExpense in object.utlaggInfo.extraExpenses) {
    tempExtraExpensesArray.push([
      object.utlaggInfo.extraExpenses[extraExpense].expenseText,
      object.utlaggInfo.extraExpenses[extraExpense].expenseCost,
    ]);
  }
  if (tempExtraExpensesArray.length > 0) {
    tempUtlaggInfoArray.push(tempExtraExpensesArray);
  }

  tempArray.push(tempUtlaggInfoArray);

  const tempFakturaInformationInfoArray = [];
  tempFakturaInformationInfoArray.push(
    object.fakturaInformationInfo.fakturaNummer
  );
  tempFakturaInformationInfoArray.push(
    object.fakturaInformationInfo.advokatbyra
  );
  tempFakturaInformationInfoArray.push(
    object.fakturaInformationInfo.advokatsNamn
  );
  tempFakturaInformationInfoArray.push(object.fakturaInformationInfo.adress);
  tempFakturaInformationInfoArray.push(
    object.fakturaInformationInfo.postnummer
  );
  tempFakturaInformationInfoArray.push(object.fakturaInformationInfo.ort);
  tempFakturaInformationInfoArray.push(object.fakturaInformationInfo.telefon);
  tempFakturaInformationInfoArray.push(object.fakturaInformationInfo.epost);
  tempFakturaInformationInfoArray.push(object.fakturaInformationInfo.orgNummer);
  tempFakturaInformationInfoArray.push(object.fakturaInformationInfo.bankgiro);

  tempArray.push(tempFakturaInformationInfoArray);

  const tempInformationOmMaletInfoArray = [];
  tempInformationOmMaletInfoArray.push(object.informationOmMaletInfo.domstol);
  tempInformationOmMaletInfoArray.push(object.informationOmMaletInfo.malnummer);
  tempInformationOmMaletInfoArray.push(object.informationOmMaletInfo.klient);

  tempArray.push(tempInformationOmMaletInfoArray);
  return tempArray;
}

export function linkArrayToObject(linkArray: any[]) {
  const linkObject: any = {};
  linkObject.date = linkArray[DATE_POSITION];

  linkObject.arbetadTidInfo = {};
  linkObject.arbetadTidInfo.hours = getHoursFromMinutes(
    linkArray[ARBETAD_TID_INFO_POSITION][MINUTES_IN_ARBETAD_TID_INFO_POSITION]
  ).hours;
  linkObject.arbetadTidInfo.minutes = getHoursFromMinutes(
    linkArray[ARBETAD_TID_INFO_POSITION][MINUTES_IN_ARBETAD_TID_INFO_POSITION]
  ).minutes;
  linkObject.arbetadTidInfo.helgdagHours = getHoursFromMinutes(
    linkArray[ARBETAD_TID_INFO_POSITION][
      HELGDAG_MINUTES_IN_ARBETAD_TID_INFO_POSITION
    ]
  ).hours;
  linkObject.arbetadTidInfo.helgdagMinutes = getHoursFromMinutes(
    linkArray[ARBETAD_TID_INFO_POSITION][
      HELGDAG_MINUTES_IN_ARBETAD_TID_INFO_POSITION
    ]
  ).minutes;
  linkObject.arbetadTidInfo.taxemal =
    linkArray[ARBETAD_TID_INFO_POSITION][TAXEMAL_IN_ARBETAD_TID_INFO_POSITION];
  linkObject.arbetadTidInfo.haktningsforhandling =
    linkArray[ARBETAD_TID_INFO_POSITION][
      HAKTNINGSFORHANDLING_IN_ARBETAD_TID_INFO_POSITION
    ];
  linkObject.arbetadTidInfo.rpu =
    linkArray[ARBETAD_TID_INFO_POSITION][RPU_IN_ARBETAD_TID_INFO_POSITION];

  linkObject.tidsspillanInfo = {};
  linkObject.tidsspillanInfo.vardagarHours = getHoursFromMinutes(
    linkArray[TIDSSPILLAN_INFO_POSITION][
      VARDAGAR_MINUTES_IN_TIDSSPILLAN_INFO_POSITION
    ]
  ).hours;
  linkObject.tidsspillanInfo.vardagarMinutes = getHoursFromMinutes(
    linkArray[TIDSSPILLAN_INFO_POSITION][
      VARDAGAR_MINUTES_IN_TIDSSPILLAN_INFO_POSITION
    ]
  ).minutes;
  linkObject.tidsspillanInfo.ovrigTidHours = getHoursFromMinutes(
    linkArray[TIDSSPILLAN_INFO_POSITION][
      OVRIG_TID_MINUTES_IN_TIDSSPILLAN_INFO_POSITION
    ]
  ).hours;
  linkObject.tidsspillanInfo.ovrigTidMinutes = getHoursFromMinutes(
    linkArray[TIDSSPILLAN_INFO_POSITION][
      OVRIG_TID_MINUTES_IN_TIDSSPILLAN_INFO_POSITION
    ]
  ).minutes;

  linkObject.utlaggInfo = {};
  linkObject.utlaggInfo.kilometers =
    linkArray[UTLAGG_INFO_POSITION][KILOMETERS_IN_UTLAGG_INFO_POSITION];
  linkObject.utlaggInfo.extraExpenses = [];
  for (let extraExpense in linkArray[UTLAGG_INFO_POSITION][
    EXTRA_EXPENSES_IN_UTLAGG_INFO_POSITION
  ]) {
    linkObject.utlaggInfo.extraExpenses.push({});
    linkObject.utlaggInfo.extraExpenses[extraExpense].expenseText =
      linkArray[UTLAGG_INFO_POSITION][EXTRA_EXPENSES_IN_UTLAGG_INFO_POSITION][
        extraExpense
      ][0];
    linkObject.utlaggInfo.extraExpenses[extraExpense].expenseCost =
      linkArray[UTLAGG_INFO_POSITION][EXTRA_EXPENSES_IN_UTLAGG_INFO_POSITION][
        extraExpense
      ][1];
  }

  linkObject.fakturaInformationInfo = {};
  linkObject.fakturaInformationInfo.fakturaNummer =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      FAKTURA_NUMMER_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];
  linkObject.fakturaInformationInfo.advokatbyra =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      ADVOKATBYRA_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];
  linkObject.fakturaInformationInfo.advokatsNamn =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      ADVOKATS_NAMN_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];
  linkObject.fakturaInformationInfo.adress =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      ADRESS_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];
  linkObject.fakturaInformationInfo.postnummer =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      POSTNUMMER_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];
  linkObject.fakturaInformationInfo.ort =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      ORT_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];
  linkObject.fakturaInformationInfo.telefon =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      TELEFON_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];
  linkObject.fakturaInformationInfo.epost =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      EPOST_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];
  linkObject.fakturaInformationInfo.orgNummer =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      ORG_NUMMER_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];
  linkObject.fakturaInformationInfo.bankgiro =
    linkArray[FAKTURA_INFORMATION_INFO_POSITION][
      BANKGIRO_IN_FAKTURA_INFORMATION_INFO_POSITION
    ];

  linkObject.informationOmMaletInfo = {};
  linkObject.informationOmMaletInfo.domstol =
    linkArray[INFORMATION_OM_MALET_INFO_POSITION][
      DOMSTOL_IN_INFORMATION_OM_MALET_INFO_POSITION
    ];
  linkObject.informationOmMaletInfo.malnummer =
    linkArray[INFORMATION_OM_MALET_INFO_POSITION][
      MALNUMMER_IN_INFORMATION_OM_MALET_INFO_POSITION
    ];
  linkObject.informationOmMaletInfo.klient =
    linkArray[INFORMATION_OM_MALET_INFO_POSITION][
      KLIENT_IN_INFORMATION_OM_MALET_INFO_POSITION
    ];

  return linkObject;
}

export function roundTo2Decimals(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
