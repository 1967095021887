import React from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  Link,
} from "@react-pdf/renderer";
import poppins from "../../../resources/fonts/Poppins/Poppins-Medium.ttf";
import poppinsLight from "../../../resources/fonts/Poppins/Poppins-Light.ttf";
import poppinsBold from "../../../resources/fonts/Poppins/Poppins-Bold.ttf";
import poppinsExtraBold from "../../../resources/fonts/Poppins/Poppins-ExtraBold.ttf";
import {
  currentDateAndTime,
  numToString,
  pdfDate,
  roundTo2Decimals,
} from "../../../services/commonFunctions";
import { ArbetadTidInfo } from "../../../models/ArbetadTidInfo";
import { TidsspillanInfo } from "../../../models/TidsspillanInfo";
import { UtlaggInfo } from "../../../models/UtlaggInfo";
import { FakturaInformationInfo } from "../../../models/FakturaInformationInfo";
import { InformationOmMaletInfo } from "../../../models/InformationOmMaletInfo";
import logo from "../../../resources/images/logo.png";

type Props = {
  date: Date | null;
  arbetadTidInfo: ArbetadTidInfo;
  sumArbetadTid: number;
  ordinarieSum: number;
  helgdagSum: number;
  tidsspillanInfo: TidsspillanInfo;
  tidsspillanSum: number;
  vardagarSum: number;
  ovrigTidSum: number;
  utlaggInfo: UtlaggInfo;
  utlaggSum: number;
  summaExMoms: number;
  summaExMomsRounded: number;
  fakturaInformationInfo: FakturaInformationInfo;
  informationOmMaletInfo: InformationOmMaletInfo;
  kmCostSum: number;
  extraExpensesSum: number;
  qrImage: string;
  createQrCode: () => string;
};

Font.register({
  family: "Poppins",
  src: poppins,
});
Font.register({
  family: "Poppins-Light",
  src: poppinsLight,
});
Font.register({
  family: "Poppins-Bold",
  src: poppinsBold,
});
Font.register({
  family: "Poppins-ExtraBold",
  src: poppinsExtraBold,
});

/* const colors = {
  basicColor: "#0014ff",
}; */

export default function PdfDocument(props: Props) {
  const styles = StyleSheet.create({
    page: {
      padding: "55px",
      fontFamily: "Poppins-Light",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerText: {
      fontFamily: "Poppins-Bold",
      fontSize: "10px",
    },
    qrCodeContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      marginRight: "10px",
    },
    qrCodeImg: {
      width: "130px",
    },
    qrCodeSubtitle: {
      fontSize: "7px",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    headerContainerLeft: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      position: "relative",
    },
    headerContainerRight: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    titleAndDateContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "260px",
      marginLeft: "20px",
    },
    courtName: {
      marginTop: "40px",
      maxWidth: "190px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "140px",
    },
    courtNameText: {
      fontFamily: "Poppins-Bold",
      fontSize: "10px",
      textAlign: "center",
    },
    clientInfoContainer: {
      marginTop: "20px",
      marginLeft: "10px",
      minWidth: "20px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    clientInfoTextContainer: { padding: "5px", border: "1px solid grey" },
    clientInfoEmptySpace: {
      height: "100%",
    },
    clientInfoText: {
      fontFamily: "Poppins-Light",
      fontSize: "10px",
    },
    listOfExpensesContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "20px",
      marginLeft: "10px",
      marginRight: "10px",
    },
    expenseContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginBottom: "15px",
    },
    expenseRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "3px",
    },
    expenseTitle: {
      fontFamily: "Poppins-Bold",
      fontSize: "10px",
      width: "235px",
    },
    expenseSubTitle: {
      fontFamily: "Poppins-Light",
      fontSize: "10px",
      width: "235px",
      paddingLeft: "5px",
    },
    expenseColumnOne: {
      fontFamily: "Poppins-Light",
      fontSize: "10px",
      width: "60px",
    },
    expenseColumnTwo: {
      fontFamily: "Poppins-Light",
      fontSize: "10px",
      width: "60px",
    },
    expenseColumnThree: {
      fontFamily: "Poppins-Bold",
      fontSize: "10px",
      width: "60px",
      textAlign: "right",
      paddingRight: "15px",
    },
    expenseColumnThreeForMoms: {
      fontFamily: "Poppins-Bold",
      fontSize: "10px",
      width: "120px",
      textAlign: "right",
      paddingRight: "15px",
    },
    expenseColumnThreeAndHalf: {
      fontFamily: "Poppins-Bold",
      fontSize: "8px",
      width: "60px",
      color: "#535353",
      textAlign: "right",
      paddingRight: "5px",
    },
    expenseColumnFour: {
      fontFamily: "Poppins-Light",
      fontSize: "10px",
      width: "60px",
      textAlign: "right",
    },
    expenseColumnFourBold: {
      fontFamily: "Poppins-Bold",
      fontSize: "10px",
      width: "60px",
      textAlign: "right",
    },
    rowGap: {
      marginTop: "15px",
    },
    extraInfo: {
      marginTop: "30px",
      marginLeft: "10px",
      marginRight: "10px",
      width: "535px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    skapadContainer: {
      fontFamily: "Poppins-Light",
      fontSize: "10px",
    },
    asteriskInfo: {
      fontFamily: "Poppins-Light",
      fontSize: "10px",
    },
    upperPart: {},
    downPart: { width: "100%", display: "flex", flexDirection: "column" },
    firmInfoContainer: {
      borderTop: "1px solid grey",
      borderBottom: "1px solid grey",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: "-25px",
      marginRight: "-25px",
    },
    firmInfoColumnOne: {
      width: "120px",
      marginLeft: "15px",
    },
    firmInfoColumnTwo: {
      width: "200px",
      marginLeft: "15px",
    },
    firmInfoColumnThree: { width: "110px", marginLeft: "15px" },
    firmInfoRow: {
      fontFamily: "Poppins-Light",
      fontSize: "10px",
    },
    firmInfoEmailLink: {
      fontFamily: "Poppins-Light",
      fontSize: "9px",
    },
    firmInfoRowBold: { fontFamily: "Poppins-Bold", fontSize: "10px" },
    footer: { marginTop: "10px" },
    logo: { width: "50px", marginLeft: "5px" },
    footerRowOne: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    footerRowTwo: { fontSize: "3px", marginLeft: "8px" },
    logoText: {
      fontFamily: "Poppins-Light",
      fontSize: "7px",
      marginRight: "5px",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.upperPart}>
          <View style={styles.headerContainer}>
            <View style={styles.headerContainerLeft}>
              <View style={styles.titleAndDateContainer}>
                <Text style={styles.headerText}>KOSTNADSRÄKNING</Text>
                <Text style={styles.headerText}>
                  {pdfDate(props.date!.toISOString())}
                </Text>
              </View>
              <View style={styles.courtName}>
                <Text style={styles.courtNameText}>
                  {props.informationOmMaletInfo.domstol === ""
                    ? " "
                    : props.informationOmMaletInfo.domstol}
                </Text>
              </View>
              <View style={styles.clientInfoContainer}>
                <View style={styles.clientInfoTextContainer}>
                  <Text style={styles.clientInfoText}>
                    Målnummer: {props.informationOmMaletInfo.malnummer}
                  </Text>
                  <Text style={styles.clientInfoText}>
                    Klient: {props.informationOmMaletInfo.klient}
                  </Text>
                  <Text style={styles.clientInfoText}>
                    Fakturanummer: {props.fakturaInformationInfo.fakturaNummer}
                  </Text>
                </View>
                <View style={styles.clientInfoEmptySpace}></View>
              </View>
            </View>
            <View style={styles.headerContainerRight}>
              <View style={styles.qrCodeContainer}>
                <Link src={props.createQrCode()}>
                  <Image src={props.qrImage} style={styles.qrCodeImg} />
                </Link>
                <Text style={styles.qrCodeSubtitle}>
                  Scanna eller klicka på QR-koden
                </Text>
                <Text style={styles.qrCodeSubtitle}>
                  för att återskapa fakturan
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.listOfExpensesContainer}>
            {props.sumArbetadTid > 0 ? (
              !props.arbetadTidInfo.taxemal &&
              (props.arbetadTidInfo.helgdagHours ||
                props.arbetadTidInfo.helgdagMinutes) ? (
                <View style={styles.expenseContainer}>
                  <Text style={styles.expenseTitle}>Arbetad tid</Text>
                  <View style={styles.expenseRow}>
                    <Text style={styles.expenseSubTitle}>Ordinarie Tid</Text>
                    <Text style={styles.expenseColumnOne}>
                      Timmar: {props.arbetadTidInfo.hours}
                    </Text>
                    <Text style={styles.expenseColumnTwo}>
                      Minuter: {props.arbetadTidInfo.minutes}
                    </Text>
                    <Text style={styles.expenseColumnThree}></Text>
                    <Text style={styles.expenseColumnThreeAndHalf}></Text>
                    <Text style={styles.expenseColumnFour}>
                      {numToString(roundTo2Decimals(props.ordinarieSum))} kr
                    </Text>
                  </View>
                  <View style={styles.expenseRow}>
                    <Text style={styles.expenseSubTitle}>
                      Häktningsförhandling helg
                    </Text>
                    <Text style={styles.expenseColumnOne}>
                      Timmar: {props.arbetadTidInfo.helgdagHours}
                    </Text>
                    <Text style={styles.expenseColumnTwo}>
                      Minuter: {props.arbetadTidInfo.helgdagMinutes}
                    </Text>
                    <Text style={styles.expenseColumnThree}></Text>
                    <Text style={styles.expenseColumnThreeAndHalf}></Text>
                    <Text style={styles.expenseColumnFour}>
                      {numToString(roundTo2Decimals(props.helgdagSum))} kr
                    </Text>
                  </View>
                  <View style={styles.expenseRow}>
                    <Text style={styles.expenseSubTitle}></Text>
                    <Text style={styles.expenseColumnOne}></Text>
                    <Text style={styles.expenseColumnTwo}></Text>
                    <Text style={styles.expenseColumnThree}>Summa:</Text>
                    <Text style={styles.expenseColumnThreeAndHalf}>
                      ({numToString(roundTo2Decimals(props.sumArbetadTid))})*
                    </Text>
                    <Text style={styles.expenseColumnFourBold}>
                      {numToString(Math.round(props.sumArbetadTid))} kr
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.expenseContainer}>
                  <View style={styles.expenseRow}>
                    <Text style={styles.expenseTitle}>
                      {props.arbetadTidInfo.taxemal
                        ? "Arbetad tid enligt taxa"
                        : "Arbetad tid"}
                    </Text>
                    <Text style={styles.expenseColumnOne}>
                      Timmar: {props.arbetadTidInfo.hours}
                    </Text>
                    <Text style={styles.expenseColumnTwo}>
                      Minuter: {props.arbetadTidInfo.minutes}
                    </Text>
                    <Text style={styles.expenseColumnThree}></Text>
                    <Text style={styles.expenseColumnThreeAndHalf}>
                      ({numToString(roundTo2Decimals(props.sumArbetadTid))})*
                    </Text>
                    <Text style={styles.expenseColumnFourBold}>
                      {numToString(Math.round(props.sumArbetadTid))} kr
                    </Text>
                  </View>
                </View>
              )
            ) : null}
            {props.tidsspillanSum > 0 ? (
              props.ovrigTidSum > 0 ? (
                <View style={styles.expenseContainer}>
                  <Text style={styles.expenseTitle}>Tidsspillan</Text>
                  <View style={styles.expenseRow}>
                    <Text style={styles.expenseSubTitle}>
                      Vardagar 8.00 - 18.00
                    </Text>
                    <Text style={styles.expenseColumnOne}>
                      Timmar: {props.tidsspillanInfo.vardagarHours}
                    </Text>
                    <Text style={styles.expenseColumnTwo}>
                      Minuter: {props.tidsspillanInfo.vardagarMinutes}
                    </Text>
                    <Text style={styles.expenseColumnThree}></Text>
                    <Text style={styles.expenseColumnThreeAndHalf}></Text>
                    <Text style={styles.expenseColumnFour}>
                      {numToString(roundTo2Decimals(props.vardagarSum))} kr
                    </Text>
                  </View>
                  <View style={styles.expenseRow}>
                    <Text style={styles.expenseSubTitle}>Övrig tid</Text>
                    <Text style={styles.expenseColumnOne}>
                      Timmar: {props.tidsspillanInfo.ovrigTidHours}
                    </Text>
                    <Text style={styles.expenseColumnTwo}>
                      Minuter: {props.tidsspillanInfo.ovrigTidMinutes}
                    </Text>
                    <Text style={styles.expenseColumnThree}></Text>
                    <Text style={styles.expenseColumnThreeAndHalf}></Text>

                    <Text style={styles.expenseColumnFour}>
                      {numToString(roundTo2Decimals(props.ovrigTidSum))} kr
                    </Text>
                  </View>
                  <View style={styles.expenseRow}>
                    <Text style={styles.expenseSubTitle}></Text>
                    <Text style={styles.expenseColumnOne}></Text>
                    <Text style={styles.expenseColumnTwo}></Text>
                    <Text style={styles.expenseColumnThree}>Summa:</Text>
                    <Text style={styles.expenseColumnThreeAndHalf}>
                      ({numToString(roundTo2Decimals(props.tidsspillanSum))})*
                    </Text>
                    <Text style={styles.expenseColumnFourBold}>
                      {numToString(Math.round(props.tidsspillanSum))} kr
                    </Text>
                  </View>
                </View>
              ) : (
                <View style={styles.expenseContainer}>
                  <View style={styles.expenseRow}>
                    <Text style={styles.expenseTitle}>Tidsspillan</Text>
                    <Text style={styles.expenseColumnOne}>
                      Timmar: {props.tidsspillanInfo.vardagarHours}
                    </Text>
                    <Text style={styles.expenseColumnTwo}>
                      Minuter: {props.tidsspillanInfo.vardagarMinutes}
                    </Text>
                    <Text style={styles.expenseColumnThree}></Text>
                    <Text style={styles.expenseColumnThreeAndHalf}>
                      ({numToString(roundTo2Decimals(props.tidsspillanSum))})*
                    </Text>
                    <Text style={styles.expenseColumnFourBold}>
                      {numToString(Math.round(props.tidsspillanSum))} kr
                    </Text>
                  </View>
                </View>
              )
            ) : null}
            {props.utlaggSum > 0 ? (
              <>
                <Text style={styles.expenseTitle}>Utlägg</Text>
                <View style={styles.expenseContainer}>
                  {props.kmCostSum > 0 ? (
                    <View style={styles.expenseRow}>
                      <Text style={styles.expenseSubTitle}>
                        Resa i kilometer
                      </Text>
                      <Text style={styles.expenseColumnOne}>
                        {props.utlaggInfo.kilometers} km
                      </Text>
                      <Text style={styles.expenseColumnTwo}></Text>
                      <Text style={styles.expenseColumnThree}></Text>
                      <Text style={styles.expenseColumnThreeAndHalf}></Text>
                      <Text style={styles.expenseColumnFour}>
                        {numToString(roundTo2Decimals(props.kmCostSum))} kr
                      </Text>
                    </View>
                  ) : null}
                  {props.utlaggInfo.extraExpenses.length > 0
                    ? props.utlaggInfo.extraExpenses.map(
                        (extraExpense, index) => (
                          <View
                            style={styles.expenseRow}
                            key={`extraExpenseRow${index}`}
                          >
                            <Text style={styles.expenseSubTitle}>
                              {extraExpense.expenseText}
                            </Text>
                            <Text style={styles.expenseColumnOne}></Text>
                            <Text style={styles.expenseColumnTwo}></Text>
                            <Text style={styles.expenseColumnThree}></Text>
                            <Text
                              style={styles.expenseColumnThreeAndHalf}
                            ></Text>
                            <Text style={styles.expenseColumnFour}>
                              {numToString(
                                roundTo2Decimals(
                                  typeof extraExpense.expenseCost === "string"
                                    ? 0
                                    : extraExpense.expenseCost
                                )
                              )}{" "}
                              kr
                            </Text>
                          </View>
                        )
                      )
                    : null}
                  <View style={styles.expenseRow}>
                    <Text style={styles.expenseSubTitle}></Text>
                    <Text style={styles.expenseColumnOne}></Text>
                    <Text style={styles.expenseColumnTwo}></Text>
                    <Text style={styles.expenseColumnThree}>Summa:</Text>
                    <Text style={styles.expenseColumnThreeAndHalf}>
                      ({numToString(roundTo2Decimals(props.utlaggSum))})*
                    </Text>
                    <Text style={styles.expenseColumnFourBold}>
                      {numToString(Math.round(props.utlaggSum))} kr
                    </Text>
                  </View>
                </View>
              </>
            ) : null}
            <View style={styles.expenseRow}>
              <Text style={styles.expenseSubTitle}></Text>
              <Text style={styles.expenseColumnOne}></Text>
              <Text style={styles.expenseColumnThreeForMoms}>
                Summa ex moms:
              </Text>
              <Text style={styles.expenseColumnThreeAndHalf}>
                ({numToString(roundTo2Decimals(props.summaExMoms))})*
              </Text>
              <Text style={styles.expenseColumnFourBold}>
                {numToString(props.summaExMomsRounded)} kr
              </Text>
            </View>
            <View style={styles.expenseRow}>
              <Text style={styles.expenseSubTitle}></Text>
              <Text style={styles.expenseColumnOne}></Text>
              <Text style={styles.expenseColumnTwo}></Text>
              <Text style={styles.expenseColumnThree}>Moms:</Text>
              <Text style={styles.expenseColumnThreeAndHalf}>
                (
                {numToString(
                  roundTo2Decimals(props.summaExMoms * 1.25 - props.summaExMoms)
                )}
                )*
              </Text>
              <Text style={styles.expenseColumnFourBold}>
                {numToString(
                  Math.round(
                    props.summaExMomsRounded * 1.25 - props.summaExMomsRounded
                  )
                )}{" "}
                kr
              </Text>
            </View>
            <View style={styles.rowGap}></View>
            <View style={styles.expenseRow}>
              <Text style={styles.expenseSubTitle}></Text>
              <Text style={styles.expenseColumnOne}></Text>
              <Text style={styles.expenseColumnThreeForMoms}>
                Summa ink moms:
              </Text>
              <Text style={styles.expenseColumnThreeAndHalf}>
                ({numToString(roundTo2Decimals(props.summaExMoms * 1.25))})*
              </Text>
              <Text style={styles.expenseColumnFourBold}>
                {numToString(Math.round(props.summaExMomsRounded * 1.25))} kr
              </Text>
            </View>
          </View>

          <View style={styles.extraInfo}>
            <View style={styles.skapadContainer}>
              <Text>Skapad {currentDateAndTime(new Date().toISOString())}</Text>
              <Text>{props.fakturaInformationInfo.advokatsNamn}</Text>
            </View>
            <View style={styles.asteriskInfo}>
              <Text>*Belopp innan avrundning</Text>
            </View>
          </View>
        </View>
        <View style={styles.downPart} wrap={false}>
          <View style={styles.firmInfoContainer}>
            <View style={styles.firmInfoColumnOne}>
              <Text style={styles.firmInfoRowBold}>
                {props.fakturaInformationInfo.advokatbyra}
              </Text>
              <Text style={styles.firmInfoRow}>
                {props.fakturaInformationInfo.adress}
              </Text>
              <Text style={styles.firmInfoRow}>
                {props.fakturaInformationInfo.postnummer}
              </Text>
              <Text style={styles.firmInfoRow}>
                {props.fakturaInformationInfo.ort}
              </Text>
            </View>
            <View style={styles.firmInfoColumnTwo}>
              <Text style={styles.firmInfoRow}>
                Telefon: {props.fakturaInformationInfo.telefon}
              </Text>
              <Text style={styles.firmInfoRow}></Text>
              <Text style={styles.firmInfoRow}>E-post:</Text>
              <Link
                style={styles.firmInfoEmailLink}
                src={`mailto:${props.fakturaInformationInfo.epost}`}
              >
                {props.fakturaInformationInfo.epost}
              </Link>
            </View>
            <View style={styles.firmInfoColumnThree}>
              <Text style={styles.firmInfoRow}></Text>

              <Text style={styles.firmInfoRow}>
                ORG NR: {props.fakturaInformationInfo.orgNummer}
              </Text>
              <Text style={styles.firmInfoRow}>
                Bankgiro: {props.fakturaInformationInfo.bankgiro}
              </Text>
              <Text style={styles.firmInfoRow}>GODKÄND FÖR F-SKATT</Text>
            </View>
          </View>
          <View style={styles.footer}>
            <View style={styles.footerRowOne}>
              <Image src={logo} style={styles.logo} />
              <Text style={styles.logoText}>
                Fakturan har skapats med hjälp av DomsTools.se. Beräkningarna är
                enligt Domstolsverkets författningssamling.
              </Text>
            </View>
            <View style={styles.footerRowTwo}>
              <Link src={props.createQrCode()}>{props.createQrCode()}</Link>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
