export class UtlaggInfo {
  kilometers: number;
  extraExpenses: { expenseText: string; expenseCost: number | string }[];

  constructor(
    kilometers: number,
    extraExpenses: { expenseText: string; expenseCost: number }[]
  ) {
    this.kilometers = kilometers;
    this.extraExpenses = extraExpenses;
  }

  static DefaultUtlaggInfo() {
    let tempDefaultUtlaggInfo = new UtlaggInfo(0, []);

    return tempDefaultUtlaggInfo;
  }
}
