export class InformationOmMaletInfo {
  domstol: string;
  malnummer: string;
  klient: string;

  constructor(domstol: string, malnummer: string, klient: string) {
    this.domstol = domstol;
    this.malnummer = malnummer;
    this.klient = klient;
  }

  static DefaultInformationOmMalet() {
    let tempDefaultInformationOmMalet = new InformationOmMaletInfo("", "", "");

    return tempDefaultInformationOmMalet;
  }
}
