import React, { useEffect, useState } from "react";
import Homepage from "./pages/homepage/Homepage";
import { getLawyerRatesByYear } from "./services/api";
import "./styles/App.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import NotSupportedBrowser from "./components/NotSupportedBrowser";

function App() {
  const [rateObj, setRateObj] = useState<any | undefined>(undefined);
  const [date, setDate] = useState<Date | null>(new Date());
  const [wrongDate, setWrongDate] = useState(false);
  const [falseBrowser, setFalseBrowser] = useState(false);

  useEffect(() => {
    //It checks if the browser is chrome or not and sets falseBrowser
    //We need to test that code because I am not sure that works for every other browser

    if (
      (!window.navigator.userAgent.includes("Chromium") &&
        window.navigator.userAgent.includes("Chrome")) ||
      window.navigator.userAgent.indexOf("CriOS") >= 0
    ) {
      console.log("CHROMIUM BASED");
      setFalseBrowser(false);
    } else {
      console.log("NOT CHROME");
      setFalseBrowser(false);
    }
  }, []);

  useEffect(() => {
    if (date) {
      getLawyerRatesByYear(date.getFullYear()).then((res) => {
        if (res === 404) {
          getLawyerRatesByYear(new Date().getFullYear()).then((res) => {
            setWrongDate(true);
            setRateObj(res);
          });
        } else {
          setWrongDate(false);
          setRateObj(res);
        }
      });
    }
  }, [date]);

  return falseBrowser ? (
    <NotSupportedBrowser
      falseBrowser={falseBrowser}
      setFalseBrowser={setFalseBrowser}
    />
  ) : rateObj ? (
    <Router>
      <Switch>
        <Route path="/:base64String">
          <Homepage
            rateObj={rateObj}
            setDate={setDate}
            date={date}
            wrongDate={wrongDate}
          />
        </Route>
        <Route path="/">
          <Homepage
            rateObj={rateObj}
            setDate={setDate}
            date={date}
            wrongDate={wrongDate}
          />
        </Route>
      </Switch>
    </Router>
  ) : (
    <div></div>
  );
}

export default App;
