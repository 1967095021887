import React, { useEffect } from "react";
import Counter from "../../../components/Counter";
import ExtraExpenses from "../../../components/ExtraExpenses";
import { ArbetadTidInfo } from "../../../models/ArbetadTidInfo";
import { TidsspillanInfo } from "../../../models/TidsspillanInfo";
import { UtlaggInfo } from "../../../models/UtlaggInfo";
import {
  deepCloneObject,
  numToString,
  roundTo2Decimals,
} from "../../../services/commonFunctions";

type Props = {
  utlaggInfo: UtlaggInfo;
  setUtlaggInfo: (param: UtlaggInfo) => void;
  utlaggSum: number;
  setUtlaggSum: (param: number) => void;
  kmRate: number;
  kmCostSum: number;
  setKmCostSum: (param: number) => void;
  extraExpensesSum: number;
  setExtraExpensesSum: (param: number) => void;
};

export default function Utlagg(props: Props) {
  useEffect(() => {
    let kmCostSum = props.kmRate * props.utlaggInfo.kilometers;

    let tempExtraExpensesSum = 0;
    for (let expense in props.utlaggInfo.extraExpenses) {
      tempExtraExpensesSum +=
        typeof props.utlaggInfo.extraExpenses[expense].expenseCost ===
          "string" ||
        isNaN(props.utlaggInfo.extraExpenses[expense].expenseCost as number)
          ? 0
          : (props.utlaggInfo.extraExpenses[expense].expenseCost as number);
    }
    props.setKmCostSum(roundTo2Decimals(kmCostSum));
    props.setExtraExpensesSum(roundTo2Decimals(tempExtraExpensesSum));
    props.setUtlaggSum(roundTo2Decimals(kmCostSum + tempExtraExpensesSum));
    // eslint-disable-next-line
  }, [props.utlaggInfo]);

  function handleChangeValue(
    type: keyof TidsspillanInfo | keyof ArbetadTidInfo | keyof UtlaggInfo,
    value: number | boolean
  ) {
    let tempUtlaggInfo = deepCloneObject(props.utlaggInfo);
    tempUtlaggInfo[type] = value;

    props.setUtlaggInfo(tempUtlaggInfo);
  }

  return (
    <div className="sectionContainer">
      <div className="sectionTitleContainer">Utlägg</div>
      <div className="hoursAndMinutesContainer">
        <p className="hoursAndMinutesSubtitle">Resa i kilometer</p>
        <Counter
          title="Kilometer"
          step={1}
          min={0}
          max={10000}
          value={props.utlaggInfo.kilometers}
          type="kilometers"
          handleChangeValue={handleChangeValue}
        />

        <div className="hoursAndMinutesSumContainer">
          <p className="hoursAndMinutesSumText">
            Belopp: {numToString(props.kmCostSum)} Kr
          </p>
        </div>
      </div>
      <div className="kilometersContainer"></div>
      <div className="extraCostsContainer">
        <ExtraExpenses
          utlaggInfo={props.utlaggInfo}
          setUtlaggInfo={props.setUtlaggInfo}
          extraExpensesSum={props.extraExpensesSum}
          setExtraExpensesSum={props.setExtraExpensesSum}
        />
      </div>
      <div className="sumContainer">
        <p className="sumText">
          Summa utlägg: {numToString(props.utlaggSum)} Kr
        </p>
        <p className="sumNumber"></p>
      </div>
    </div>
  );
}
