export const DATE_POSITION = 0;
export const ARBETAD_TID_INFO_POSITION = 1;
export const TIDSSPILLAN_INFO_POSITION = 2;
export const UTLAGG_INFO_POSITION = 3;
export const FAKTURA_INFORMATION_INFO_POSITION = 4;
export const INFORMATION_OM_MALET_INFO_POSITION = 5;

export const MINUTES_IN_ARBETAD_TID_INFO_POSITION = 0;
export const HELGDAG_MINUTES_IN_ARBETAD_TID_INFO_POSITION = 1;
export const TAXEMAL_IN_ARBETAD_TID_INFO_POSITION = 2;
export const HAKTNINGSFORHANDLING_IN_ARBETAD_TID_INFO_POSITION = 3;
export const RPU_IN_ARBETAD_TID_INFO_POSITION = 4;

export const VARDAGAR_MINUTES_IN_TIDSSPILLAN_INFO_POSITION = 0;
export const OVRIG_TID_MINUTES_IN_TIDSSPILLAN_INFO_POSITION = 1;

export const KILOMETERS_IN_UTLAGG_INFO_POSITION = 0;
export const EXTRA_EXPENSES_IN_UTLAGG_INFO_POSITION = 1;

export const FAKTURA_NUMMER_IN_FAKTURA_INFORMATION_INFO_POSITION = 0;
export const ADVOKATBYRA_IN_FAKTURA_INFORMATION_INFO_POSITION = 1;
export const ADVOKATS_NAMN_IN_FAKTURA_INFORMATION_INFO_POSITION = 2;
export const ADRESS_IN_FAKTURA_INFORMATION_INFO_POSITION = 3;
export const POSTNUMMER_IN_FAKTURA_INFORMATION_INFO_POSITION = 4;
export const ORT_IN_FAKTURA_INFORMATION_INFO_POSITION = 5;
export const TELEFON_IN_FAKTURA_INFORMATION_INFO_POSITION = 6;
export const EPOST_IN_FAKTURA_INFORMATION_INFO_POSITION = 7;
export const ORG_NUMMER_IN_FAKTURA_INFORMATION_INFO_POSITION = 8;
export const BANKGIRO_IN_FAKTURA_INFORMATION_INFO_POSITION = 9;

export const DOMSTOL_IN_INFORMATION_OM_MALET_INFO_POSITION = 0;
export const MALNUMMER_IN_INFORMATION_OM_MALET_INFO_POSITION = 1;
export const KLIENT_IN_INFORMATION_OM_MALET_INFO_POSITION = 2;
