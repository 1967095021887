import React from "react";
import exitImg from "../resources/images/120-removeImg.svg";

type Props = {
  id: string;
  backGroundColor: string;
  blur?: boolean;
  cancelFunction: () => void;
  warningTitle?: string;
  warningTextContainer?: any;
  warningSubTextContainer?: any;
  zIndex: number;
};

export default function FullScreenPopMessage(props: Props) {
  return (
    <>
      <div
        id="fullScreenPopMessageBackground"
        className="fullScreenPopMessageBackground"
        style={
          props.blur
            ? {
                backgroundColor: props.backGroundColor,
                opacity: 0.8,
                zIndex: props.zIndex,
              }
            : {
                backgroundColor: props.backGroundColor,
                opacity: 1,
                zIndex: props.zIndex,
              }
        }
      ></div>
      <div
        className="fullScreenPopMessageContainer"
        style={{ zIndex: props.zIndex + 1 }}
      >
        <div className="popUpContainer" id={"popUpContainer"}>
          <div className="warningMessageContainer" id={"popUp3"}>
            <h2 className="warningTitle" id={`popUp4-warningTitle-${props.id}`}>
              {props.warningTitle}
            </h2>
            <div className="warningTextContainer" id={"popUp5"}>
              {props.warningTextContainer}
            </div>
            {props.warningSubTextContainer ? (
              <div className="warningSubTextContainer" id={"popUp6"}>
                {props.warningSubTextContainer}
              </div>
            ) : null}
          </div>
          <div
            className="exitButtonContainer"
            onClick={props.cancelFunction}
            id={"popUp1"}
          >
            <img
              src={exitImg}
              alt="exitImg"
              className="exitButtonImg"
              id={"popUp2"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
