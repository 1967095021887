import React from "react";
import { UtlaggInfo } from "../models/UtlaggInfo";
import TextField from "@mui/material/TextField";
import { deepCloneObject, numToString } from "../services/commonFunctions";
import plusNoCircle from "../resources/images/plusNoCircle.svg";
import { InputAdornment } from "@mui/material";
import bin from "../resources/images/recycle-bin-line.svg";

type Props = {
  utlaggInfo: UtlaggInfo;
  setUtlaggInfo: (param: UtlaggInfo) => void;
  extraExpensesSum: number;
  setExtraExpensesSum: (param: number) => void;
};

export default function ExtraExpenses(props: Props) {
  function addExtraExpenseSection() {
    let tempUtlaggInfo = deepCloneObject(props.utlaggInfo);
    tempUtlaggInfo.extraExpenses.push({ expenseCost: 0, expenseText: "" });
    props.setUtlaggInfo(tempUtlaggInfo);
  }

  function deleteExtraExpenseSection(index: number) {
    let tempUtlaggInfo = deepCloneObject(props.utlaggInfo);

    tempUtlaggInfo.extraExpenses.splice(index, 1);
    props.setUtlaggInfo(tempUtlaggInfo);
  }

  function handleChangeValue(
    type: string,
    index: number,
    value: string | number
  ) {
    let tempUtlaggInfo = deepCloneObject(props.utlaggInfo);
    if (type === "expenseText") {
      tempUtlaggInfo.extraExpenses[index][type] = value;
    } else {
      tempUtlaggInfo.extraExpenses[index][type] = parseFloat(value as string);
    }
    props.setUtlaggInfo(tempUtlaggInfo);
  }
  return (
    <div className="extraCostButtonContainer">
      {props.utlaggInfo.extraExpenses.length > 0
        ? props.utlaggInfo.extraExpenses.map((extraExpense, index) => (
            <div className="extraCostSection" key={`extraCostSection${index}`}>
              <TextField
                id={`extraExpenseText${index}`}
                label="Andra utlägg"
                value={extraExpense.expenseText}
                variant="standard"
                onChange={(e) =>
                  handleChangeValue("expenseText", index, e.target.value)
                }
                className="extraExpenseText"
              />
              <div className="secondRowOfSection">
                <TextField
                  id={`extraExpenseCost${index}`}
                  label="Kostnad (Ex. moms)"
                  value={
                    isNaN(extraExpense.expenseCost as number)
                      ? ""
                      : extraExpense.expenseCost
                  }
                  type={
                    isNaN(extraExpense.expenseCost as number)
                      ? "string"
                      : "number"
                  }
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">kr</InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    handleChangeValue("expenseCost", index, e.target.value)
                  }
                  className="extraExpenseText"
                />
                <div className="deleteSectionContainer">
                  <img
                    src={bin}
                    alt="bin"
                    className="deleteSectionImg"
                    onClick={() => deleteExtraExpenseSection(index)}
                  />
                </div>
              </div>
            </div>
          ))
        : null}
      <button className="extraCost" onClick={addExtraExpenseSection}>
        <img
          src={plusNoCircle}
          alt="plusNoCircle"
          className="extraCostButtonImg"
        />
        <p className="extraCostButtonLabel">lägg till kostnad</p>
      </button>
      <div className="hoursAndMinutesSumContainer">
        <p className="hoursAndMinutesSumText">
          Belopp: {numToString(props.extraExpensesSum)} Kr
        </p>
      </div>
    </div>
  );
}
