import React from "react";
import exitImg from "../../../resources/images/exitImg.svg";

type Props = {
  backGroundColor: string;
  blur?: boolean;

  cancelFunction: () => void;

  zIndex: number;
};

export default function InformationPopUp(props: Props) {
  function clickOnEmptySpace(e: any) {
    if (!e.target.id.includes("popUp")) {
      props.cancelFunction();
    }
  }
  return (
    <>
      <div
        id="fullScreenPopMessageBackground"
        className="fullScreenPopMessageBackground"
        onClick={clickOnEmptySpace}
        style={
          props.blur
            ? {
                backgroundColor: props.backGroundColor,
                opacity: 0.8,
                zIndex: props.zIndex,
              }
            : {
                backgroundColor: props.backGroundColor,
                opacity: 1,
                zIndex: props.zIndex,
              }
        }
      ></div>
      <div
        className="fullScreenPopMessageContainer"
        onClick={clickOnEmptySpace}
        style={{ zIndex: props.zIndex + 1 }}
      >
        <div className="popUpContainer" id={"popUpContainer"}>
          <div className="popUpMessageContainer" id={"popUp3"}>
            <h2 className="popUpTitle" id={"popUp4"}>
              DomsTools
            </h2>
            <p className="popUpParagraph" id={"popUp5"}>
              DomsTools skapar verktyg för aktörer i domstol.
            </p>
            <p className="popUpParagraph" id={"popUp6"}>
              Detta första verktyg som tillhandahålls är en digital lösning för
              att snabbt och enkelt skapa och verifiera kostnadsräkningar i
             mål där staten betalar ut ersättning enligt olika taxor eller normer.
            </p>
            <p className="popUpParagraph" id={"popUp7"}>
              Verktyget har skapats av Eperoto AB i samverkan med jurister
              verksamma i domstol.
            </p>
            <p className="popUpParagraph" id={"popUp9"}>
              Synpunkter på verktyget mottages tacksamt.
            </p>
            <p className="popUpParagraph" id={"popUp10"}>
              Om lösningen kommer till användning och uppskattas kan lösningen utökas och fler
              verktyg komma att lanseras även på andra områden.
            </p>
            <p className="popUpParagraph" id={"popUp11"}>
              Domstools har ännu inte genomgått extern verifiering. Användaren
              ansvarar själv för riktigheten av beräkningarna.
            </p>
            <p className="popUpParagraph" id={"popUp12"}>
              Domstools lösning genererar alla beräkningar och fakturor
              fullständigt lokalt på användarens egen dator eller mobiltelefon.
              Detta medför att Domstools inte sparar, behandlar eller skickar
              några personuppgifter. Användaren ansvarar själv för att
              personuppgifter som fylls i verktyget hanteras på ett säkert och
              lagenligt sätt.
            </p>
            <p className="popUpParagraph" id={"popUp13"}>
              Kontaktinformation till produktägare:
            </p>
            <p className="popUpParagraph" id={"popUp14"}>
              <a
                href="mailto:info@eperoto.com"
                className="greenSpan"
                id={"popUp14a"}
              >
                info@eperoto.com
              </a>
              <br />
              Eperoto AB, 559249-1202
              <br /> Läraregatan 3<br /> 411 33
              <br />
              Göteborg
            </p>
            <p className="popUpParagraph" id={"popUp15"}>
              <a
                href="https://www.eperoto.com"
                className="greenSpan"
                id={"popUp15a"}
              >
                www.eperoto.com
              </a>
            </p>
          </div>

          <div
            className="exitButtonContainer"
            onClick={props.cancelFunction}
            id={"popUp1"}
          >
            <img
              src={exitImg}
              alt="exitImg"
              className="exitButtonImg"
              id={"popUp2"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
