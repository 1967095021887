import React, { useEffect } from "react";
import Counter from "../../../components/Counter";
import { ArbetadTidInfo } from "../../../models/ArbetadTidInfo";
import { TidsspillanInfo } from "../../../models/TidsspillanInfo";
import { UtlaggInfo } from "../../../models/UtlaggInfo";
import {
  deepCloneObject,
  numToString,
  roundTo2Decimals,
} from "../../../services/commonFunctions";
type Props = {
  tidsspillanInfo: TidsspillanInfo;
  setTidsspillanInfo: (param: TidsspillanInfo) => void;
  vardagarRate: number;
  ovrigTidRate: number;
  tidsspillanSum: number;
  setTidsspillanSum: (param: number) => void;
  vardagarSum: number;
  setVardagarSum: (param: number) => void;
  ovrigTidSum: number;
  setOvrigTidSum: (param: number) => void;
};
export default function Tidsspillan(props: Props) {
  useEffect(() => {
    let totalVardagarHours =
      props.tidsspillanInfo.vardagarHours +
      props.tidsspillanInfo.vardagarMinutes / 60;
    let totalOvrigTidHours =
      props.tidsspillanInfo.ovrigTidHours +
      props.tidsspillanInfo.ovrigTidMinutes / 60;

    let tempVardagarSum = props.vardagarRate * totalVardagarHours;
    let tempOvrigTidSum = props.ovrigTidRate * totalOvrigTidHours;

    props.setVardagarSum(roundTo2Decimals(tempVardagarSum));
    props.setOvrigTidSum(roundTo2Decimals(tempOvrigTidSum));
    props.setTidsspillanSum(
      roundTo2Decimals(tempVardagarSum + tempOvrigTidSum)
    );
    // eslint-disable-next-line
  }, [props.tidsspillanInfo]);

  function handleChangeValue(
    type: keyof TidsspillanInfo | keyof ArbetadTidInfo | keyof UtlaggInfo,
    value: number | boolean
  ) {
    let tempTidsspillanInfo = deepCloneObject(props.tidsspillanInfo);
    tempTidsspillanInfo[type] = value;

    props.setTidsspillanInfo(tempTidsspillanInfo);
  }

  return (
    <div className="sectionContainer">
      <div className="sectionTitleContainer">Tidsspillan</div>
      <div className="hoursAndMinutesContainer">
        <p className="hoursAndMinutesSubtitle">Vardagar 8.00 - 18.00</p>
        <Counter
          title="Timmar"
          step={1}
          min={0}
          max={100}
          value={props.tidsspillanInfo.vardagarHours}
          type="vardagarHours"
          handleChangeValue={handleChangeValue}
        />
        <Counter
          title="Minuter"
          step={5}
          min={0}
          max={59}
          type="vardagarMinutes"
          value={props.tidsspillanInfo.vardagarMinutes}
          handleChangeValue={handleChangeValue}
        />
        <div className="timeCostContainer">
          <p className="timeCostText">
            (Timkostnad: <span>{numToString(props.vardagarRate)}</span> kr)
          </p>
        </div>
        <div className="hoursAndMinutesSumContainer">
          <p className="hoursAndMinutesSumText">
            Ersättning vardagar: {numToString(props.vardagarSum)} Kr
          </p>
        </div>
      </div>
      <div className="hoursAndMinutesContainer">
        <p className="hoursAndMinutesSubtitle">Övrig tid</p>
        <Counter
          title="Timmar"
          step={1}
          min={0}
          max={100}
          value={props.tidsspillanInfo.ovrigTidHours}
          type="ovrigTidHours"
          handleChangeValue={handleChangeValue}
        />
        <Counter
          title="Minuter"
          step={5}
          min={0}
          max={59}
          type="ovrigTidMinutes"
          value={props.tidsspillanInfo.ovrigTidMinutes}
          handleChangeValue={handleChangeValue}
        />
        <div className="timeCostContainer">
          <p className="timeCostText">
            (Timkostnad: <span>{numToString(props.ovrigTidRate)}</span> kr)
          </p>
        </div>
        <div className="hoursAndMinutesSumContainer">
          <p className="hoursAndMinutesSumText">
            Ersättning övrig tid: {numToString(props.ovrigTidSum)} Kr
          </p>
        </div>
      </div>
      <div className="sumContainer">
        <p className="sumText">
          Summa tidsspillan: {numToString(props.tidsspillanSum)} Kr
        </p>
        <p className="sumNumber"></p>
      </div>
    </div>
  );
}
