import React, { useEffect, useState } from "react";
import { ArbetadTidInfo } from "../../models/ArbetadTidInfo";
import { FakturaInformationInfo } from "../../models/FakturaInformationInfo";
import { InformationOmMaletInfo } from "../../models/InformationOmMaletInfo";
import { TidsspillanInfo } from "../../models/TidsspillanInfo";
import { UtlaggInfo } from "../../models/UtlaggInfo";
import logoImg from "../../resources/images/logo_domstools.svg";
import infoImg from "../../resources/images/infoImg.svg";
import ArbetadTid from "./homepageComponents/ArbetadTid";
import Datum from "./homepageComponents/Datum";
import Sammanstallning from "./homepageComponents/Sammanstallning";
import Tidsspillan from "./homepageComponents/Tidsspillan";
import Utlagg from "./homepageComponents/Utlagg";
import IncvoiceInfo from "./homepageComponents/InvoiceInfo";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import {
  findTodaysDate,
  linkArrayToObject,
  linkObjectToArray,
} from "../../services/commonFunctions";
import PdfDocument from "./pdfComponents/PdfDocument";
import QRCode from "qrcode.react";
import * as htmlToImage from "html-to-image";
import { useParams, useHistory } from "react-router-dom";
import { Params } from "../../models/generalTypes";
import { version } from "../../version";
import InformationPopUp from "./homepageComponents/InformationPopUp";
import FAQPopUp from "./homepageComponents/FAQPopUp";

type Props = {
  rateObj: any | undefined;
  setDate: (date: Date | null) => void;
  date: Date | null;
  wrongDate: boolean;
};

export default function Homepage(props: Props) {
  //DEBUGGING THE PDF
  const pdfDebuggerOn = false;

  const params = useParams<Params>();
  const history = useHistory();

  const [openInformationPopUp, setInformationOpenPopUp] = useState(false);
  const [openFAQPopUp, setFAQOpenPopUp] = useState(false);
  const [arbetadTidInfo, setArbetadTidInfo] = useState<ArbetadTidInfo>(
    ArbetadTidInfo.DefaultArbetadTidInfo()
  );
  const [sumArbetadTid, setSumArbetadTid] = useState(0);
  const [ordinarieSum, setOrdinarieSum] = useState(0);
  const [helgdagSum, setHelgdagSum] = useState(0);

  const [tidsspillanInfo, setTidsspillanInfo] = useState<TidsspillanInfo>(
    TidsspillanInfo.DefaultTidsspillanInfo()
  );
  const [tidsspillanSum, setTidsspillanSum] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [vardagarSum, setVardagarSum] = useState(0);
  const [ovrigTidSum, setOvrigTidSum] = useState(0);

  const [utlaggInfo, setUtlaggInfo] = useState<UtlaggInfo>(
    UtlaggInfo.DefaultUtlaggInfo()
  );
  const [utlaggSum, setUtlaggSum] = useState(0);
  const [kmCostSum, setKmCostSum] = useState(0);
  const [extraExpensesSum, setExtraExpensesSum] = useState(0);

  const [fakturaInformationInfo, setFakturaInformationInfo] =
    useState<FakturaInformationInfo>(
      FakturaInformationInfo.DefaultFakturaInforamtionInfo()
    );
  const [informationOmMaletInfo, setInformationOmMaletInfo] =
    useState<InformationOmMaletInfo>(
      InformationOmMaletInfo.DefaultInformationOmMalet()
    );
  const [summaExMoms, setSummaExMoms] = useState(0);
  const [summaExMomsRounded, setSummaExMomsRounded] = useState(0);
  const [createAndDownLoadPdf, setCreateAndDownLoadPdf] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [qrCodeImageState, setQrCodeImageState] = useState<undefined | string>(
    undefined
  );
  const [fakturaInformationMenu, setFakturaInformationMenu] = useState(false);

  useEffect(() => {
    if (arbetadTidInfo.taxemal) {
      const minutes = arbetadTidInfo.hours * 60 + arbetadTidInfo.minutes;
      let rate = 0;
      props.rateObj.timetables.forEach((timetable: any) => {
        const lower = Number.parseInt(timetable.time.split("-")[0]);
        const upper = Number.parseInt(timetable.time.split("-")[1]);

        if (minutes <= upper && minutes >= lower) {
          rate = timetable.basic;
        }
        setHourlyRate(rate);
      });
    } else {
      setHourlyRate(props.rateObj.arbetad_tid);
    }
    // eslint-disable-next-line
  }, [arbetadTidInfo]);
  useEffect(() => {
    let tempSummaExMoms = sumArbetadTid + tidsspillanSum + utlaggSum;
    setSummaExMoms(tempSummaExMoms);
    let tempSummaExMomsRounded =
      Math.round(sumArbetadTid) +
      Math.round(tidsspillanSum) +
      Math.round(utlaggSum);
    setSummaExMomsRounded(tempSummaExMomsRounded);
  }, [sumArbetadTid, tidsspillanSum, utlaggSum]);

  useEffect(() => {
    if (params.base64String) {
      const object64String = params.base64String.replaceAll("-%20", "");
      if (params.base64String.includes("-%20")) {
        history.replace("/" + object64String);
      } else {
        const objectString = Buffer.from(object64String, "base64").toString(
          "utf8"
        );
        try {
          const objectArray = JSON.parse(objectString);
          const object = linkArrayToObject(objectArray);

          props.setDate(new Date(object.date));
          setArbetadTidInfo(object.arbetadTidInfo);
          setFakturaInformationInfo(object.fakturaInformationInfo);
          setInformationOmMaletInfo(object.informationOmMaletInfo);
          setTidsspillanInfo(object.tidsspillanInfo);
          setUtlaggInfo(object.utlaggInfo);
          setFakturaInformationMenu(true);
        } catch (error) {
          console.log(error);
          history.replace("/");
        }
      }
    }
    // eslint-disable-next-line
  }, [params.base64String]);

  function createQrCode() {
    const tempObject = {
      date: props.date,
      arbetadTidInfo: arbetadTidInfo,
      tidsspillanInfo: tidsspillanInfo,
      utlaggInfo: utlaggInfo,
      fakturaInformationInfo: fakturaInformationInfo,
      informationOmMaletInfo: informationOmMaletInfo,
    };

    const tempArray = linkObjectToArray(tempObject);

    let tempArrayString = JSON.stringify(tempArray);

    let base64String = Buffer.from(tempArrayString).toString("base64");
    let link = process.env.REACT_APP_API_HOME_URL + "#/" + base64String;

    return link;
  }

  useEffect(() => {
    if (loadingPdf) {
      setCreateAndDownLoadPdf(false);
      createQrCodeImg();
    }
    // eslint-disable-next-line
  }, [loadingPdf]);

  async function createQrCodeImg() {
    if (loadingPdf) {
      const qrElement = document.getElementById("qrCodeContainer");
      if (qrElement) {
        const qrCodeImage = await htmlToImage.toPng(qrElement!, {});
        setQrCodeImageState(qrCodeImage);
        setCreateAndDownLoadPdf(true);
        if (!pdfDebuggerOn) {
          setTimeout(() => {
            completeDownload();
          }, 300);
        } else {
          setLoadingPdf(false);
        }
      }
    }
  }

  function completeDownload() {
    setTimeout(() => {
      let link = document.getElementsByTagName("a")[0];

      if (link.text === "Loading document...") {
        completeDownload();
      } else {
        link.click();
        setTimeout(() => {
          setLoadingPdf(false);
          setCreateAndDownLoadPdf(false);
        }, 300);
      }
    }, 2000);
  }

  return (
    <div className="homepageContainer">
      <div className="logoContainer">
        <img src={logoImg} alt="logoImg" className="logoImg" />
        <p className="logoSubtitle">Beta Version {version}</p>
      </div>
      <div className="informationButtonContainer">
        <div
          className="informationButton"
          onClick={() => setInformationOpenPopUp(true)}
        >
          <img src={infoImg} alt="infoImg" className="infoImg" />
          <p className="infoButtonText">Information om Domstools</p>
        </div>
      </div>

      <div className="informationButtonContainer">
        <div
          className="informationButton"
          onClick={() => setFAQOpenPopUp(true)}
        >
          <img src={infoImg} alt="infoImg" className="infoImg" />
          <p className="infoButtonText">Vanliga frågor</p>
        </div>
      </div>
      {openInformationPopUp ? (
        <InformationPopUp
          backGroundColor="white"
          blur
          cancelFunction={() => setInformationOpenPopUp(false)}
          zIndex={2000}
        />
      ) : null}
      {openFAQPopUp ? (
        <FAQPopUp
          backGroundColor="white"
          blur
          cancelFunction={() => setFAQOpenPopUp(false)}
          zIndex={2000}
        />
      ) : null}
      <Datum
        date={props.date}
        setDate={props.setDate}
        wrongDate={props.wrongDate}
      />
      <ArbetadTid
        arbetadTidInfo={arbetadTidInfo}
        setArbetadTidInfo={setArbetadTidInfo}
        hourlyRate={hourlyRate}
        helgdagHourlyRate={props.rateObj.arbetad_tid_helg}
        haktningsforhandlingRate={props.rateObj.hkf}
        rpuRate={props.rateObj.rpu}
        sumArbetadTid={sumArbetadTid}
        setSumArbetadTid={setSumArbetadTid}
        ordinarieSum={ordinarieSum}
        setOrdinarieSum={setOrdinarieSum}
        helgdagSum={helgdagSum}
        setHelgdagSum={setHelgdagSum}
      />
      <Tidsspillan
        tidsspillanInfo={tidsspillanInfo}
        setTidsspillanInfo={setTidsspillanInfo}
        vardagarRate={props.rateObj.tidsspillan}
        ovrigTidRate={props.rateObj.tidsspillan_odd}
        tidsspillanSum={tidsspillanSum}
        setTidsspillanSum={setTidsspillanSum}
        vardagarSum={vardagarSum}
        setVardagarSum={setVardagarSum}
        ovrigTidSum={ovrigTidSum}
        setOvrigTidSum={setOvrigTidSum}
      />
      <Utlagg
        utlaggInfo={utlaggInfo}
        setUtlaggInfo={setUtlaggInfo}
        utlaggSum={utlaggSum}
        setUtlaggSum={setUtlaggSum}
        kmRate={props.rateObj.rate_per_km}
        kmCostSum={kmCostSum}
        setKmCostSum={setKmCostSum}
        extraExpensesSum={extraExpensesSum}
        setExtraExpensesSum={setExtraExpensesSum}
      />
      <Sammanstallning
        sumArbetadTid={sumArbetadTid}
        tidsspillanSum={tidsspillanSum}
        utlaggSum={utlaggSum}
        summaExMoms={summaExMoms}
        summaExMomsRounded={summaExMomsRounded}
      />
      <IncvoiceInfo
        fakturaInformationInfo={fakturaInformationInfo}
        setFakturaInformationInfo={setFakturaInformationInfo}
        informationOmMaletInfo={informationOmMaletInfo}
        setInformationOmMaletInfo={setInformationOmMaletInfo}
        setCreateAndDownLoadPdf={setCreateAndDownLoadPdf}
        setLoadingPdf={setLoadingPdf}
        fakturaInformationMenu={fakturaInformationMenu}
        setFakturaInformationMenu={setFakturaInformationMenu}
      />
      {loadingPdf ? (
        <>
          <div className="downloadingPdfMessageContainer">
            <p className="downloadingPdfMessage">Downloading Pdf...</p>
          </div>
          <div className="qrCodeContainer" id="qrCodeContainer">
            <QRCode value={createQrCode()} size={1000} renderAs="svg" />
          </div>
        </>
      ) : null}
      {createAndDownLoadPdf && !pdfDebuggerOn ? (
        <PDFDownloadLink
          document={
            <PdfDocument
              date={props.date}
              arbetadTidInfo={arbetadTidInfo}
              sumArbetadTid={sumArbetadTid}
              ordinarieSum={ordinarieSum}
              helgdagSum={helgdagSum}
              tidsspillanInfo={tidsspillanInfo}
              tidsspillanSum={tidsspillanSum}
              utlaggInfo={utlaggInfo}
              utlaggSum={utlaggSum}
              fakturaInformationInfo={fakturaInformationInfo}
              informationOmMaletInfo={informationOmMaletInfo}
              summaExMoms={summaExMoms}
              summaExMomsRounded={summaExMomsRounded}
              vardagarSum={vardagarSum}
              ovrigTidSum={ovrigTidSum}
              kmCostSum={kmCostSum}
              extraExpensesSum={extraExpensesSum}
              createQrCode={createQrCode}
              qrImage={qrCodeImageState!}
            />
          }
          fileName={`DomsTools-${findTodaysDate()}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download now!"
          }
        </PDFDownloadLink>
      ) : null}
      {createAndDownLoadPdf && pdfDebuggerOn ? (
        <PDFViewer width={1000} height={2000}>
          <PdfDocument
            date={props.date}
            arbetadTidInfo={arbetadTidInfo}
            sumArbetadTid={sumArbetadTid}
            ordinarieSum={ordinarieSum}
            helgdagSum={helgdagSum}
            tidsspillanInfo={tidsspillanInfo}
            tidsspillanSum={tidsspillanSum}
            utlaggInfo={utlaggInfo}
            utlaggSum={utlaggSum}
            fakturaInformationInfo={fakturaInformationInfo}
            informationOmMaletInfo={informationOmMaletInfo}
            summaExMoms={summaExMoms}
            summaExMomsRounded={summaExMomsRounded}
            vardagarSum={vardagarSum}
            ovrigTidSum={ovrigTidSum}
            kmCostSum={kmCostSum}
            extraExpensesSum={extraExpensesSum}
            createQrCode={createQrCode}
            qrImage={qrCodeImageState!}
          />
        </PDFViewer>
      ) : null}
      <div className="emptySpace"></div>
    </div>
  );
}
