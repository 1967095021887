export class TidsspillanInfo {
  vardagarHours: number;
  vardagarMinutes: number;
  ovrigTidHours: number;
  ovrigTidMinutes: number;

  constructor(
    vardagarHours: number,
    vardagarMinutes: number,
    ovrigTidHours: number,
    ovrigTidMinutes: number
  ) {
    this.vardagarHours = vardagarHours;
    this.vardagarMinutes = vardagarMinutes;
    this.ovrigTidHours = ovrigTidHours;
    this.ovrigTidMinutes = ovrigTidMinutes;
  }

  static DefaultTidsspillanInfo() {
    let tempDefaultTidsspillanInfo = new TidsspillanInfo(0, 0, 0, 0);

    return tempDefaultTidsspillanInfo;
  }
}
