import { TextField } from "@mui/material";
import React from "react";
import { InformationOmMaletInfo } from "../../../models/InformationOmMaletInfo";

import { deepCloneObject } from "../../../services/commonFunctions";

type Props = {
  informationOmMaletInfo: InformationOmMaletInfo;
  setInformationOmMaletInfo: (param: InformationOmMaletInfo) => void;
};

export default function FakturaInformation(props: Props) {
  function handleChangeValue(
    type: keyof InformationOmMaletInfo,
    value: string
  ) {
    let tempInformationOmMaletInfo = deepCloneObject(
      props.informationOmMaletInfo
    );
    tempInformationOmMaletInfo[type] = value;
    props.setInformationOmMaletInfo(tempInformationOmMaletInfo);
  }

  return (
    <div className="sectionContainer">
      <div className="sectionTitleContainer">Infomation om målet</div>
      <div className="inputsContainer">
        <TextField
          label="Domstol"
          value={props.informationOmMaletInfo.domstol}
          variant="standard"
          onChange={(e) => handleChangeValue("domstol", e.target.value)}
          className="input"
        />
        <TextField
          label="Målnummer"
          value={props.informationOmMaletInfo.malnummer}
          variant="standard"
          onChange={(e) => handleChangeValue("malnummer", e.target.value)}
          className="input"
        />
        <TextField
          label="Klient"
          value={props.informationOmMaletInfo.klient}
          variant="standard"
          onChange={(e) => handleChangeValue("klient", e.target.value)}
          className="input"
        />
      </div>
    </div>
  );
}
