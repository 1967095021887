import React from "react";
import { ArbetadTidInfo } from "../models/ArbetadTidInfo";
import { TidsspillanInfo } from "../models/TidsspillanInfo";
import { UtlaggInfo } from "../models/UtlaggInfo";
import minusImg from "../resources/images/minus.svg";
import plusImg from "../resources/images/plus.svg";

type Props = {
  title: string;
  step: number;
  min: number;
  max: number;
  value: number;
  type: keyof TidsspillanInfo | keyof ArbetadTidInfo | keyof UtlaggInfo;
  handleChangeValue: (
    type: keyof TidsspillanInfo | keyof ArbetadTidInfo | keyof UtlaggInfo,
    value: number | boolean
  ) => void;
};

export default function Counter(props: Props) {
  function handleMinusButton() {
    let tempValue = props.value;
    tempValue -= props.step;
    if (tempValue < props.min) {
      tempValue = props.min;
    }
    props.handleChangeValue(props.type, tempValue);
  }
  function handlePlusButton() {
    let tempValue = props.value;
    tempValue += props.step;
    if (tempValue > props.max) {
      tempValue = props.max;
    }
    props.handleChangeValue(props.type, tempValue);
  }

  return (
    <div className="counterContainer">
      <div className="counterMinusContainer">
        <img
          src={minusImg}
          alt="minusImg"
          className="counterMinusImg"
          onClick={handleMinusButton}
        />
      </div>
      <div className="counterPlusContainer">
        <img
          src={plusImg}
          alt="plusImg"
          className="counterPlusImg"
          onClick={handlePlusButton}
        />
      </div>
      <input
        type="number"
        className="counterInput"
        max={props.max}
        min={props.min}
        value={props.value}
        onChange={(e) =>
          props.handleChangeValue(props.type, parseInt(e.target.value))
        }
      />
      <div className="counterTitleContainer">
        <p className="counterTitle">{props.title}</p>
      </div>
    </div>
  );
}
