import React from "react";
import FullScreenPopMessage from "./FullScreenPopMessage";

type Props = {
  falseBrowser: boolean;
  setFalseBrowser: (param: boolean) => void;
};

export default function NotSupportedBrowser(props: Props) {
  function cancelFunction() {
    props.setFalseBrowser(false);
  }
  function clickOnEmptySpace(e: any) {
    props.setFalseBrowser(false);
  }
  return (
    <>
      <div className="loginBackground-notSupported" onClick={clickOnEmptySpace}>
        <FullScreenPopMessage
          id="notSupportedBrowserPopUp"
          zIndex={1900}
          backGroundColor="white"
          cancelFunction={cancelFunction}
          warningTitle={"Denna sida stöder inte Safari som webbläsare :("}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-notSupportedBrowser"
                data-textattribute="description-9a,description-9b,description-9c"
              >
                Vänligen använd <strong>Google Chrome</strong>,
                <strong>Edge</strong>, eller annan{" "}
                <strong>Chromiumbaserad</strong> webbläsare. <br />
                Du kan fortsätta till sidan, men vissa funktioner — såsom att
                skapa och ladda ner PDF — kanske inte kommer att fungera.
              </p>
            </>
          }
        />
      </div>
    </>
  );
}
