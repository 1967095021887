import React from "react";
import exitImg from "../../../resources/images/exitImg.svg";

type Props = {
  backGroundColor: string;
  blur?: boolean;

  cancelFunction: () => void;

  zIndex: number;
};

export default function FullScreenPopMessage(props: Props) {
  function clickOnEmptySpace(e: any) {
    if (!e.target.id.includes("popUp")) {
      props.cancelFunction();
    }
  }
  return (
    <>
      <div
        id="fullScreenPopMessageBackground"
        className="fullScreenPopMessageBackground"
        onClick={clickOnEmptySpace}
        style={
          props.blur
            ? {
                backgroundColor: props.backGroundColor,
                opacity: 0.8,
                zIndex: props.zIndex,
              }
            : {
                backgroundColor: props.backGroundColor,
                opacity: 1,
                zIndex: props.zIndex,
              }
        }
      ></div>
      <div
        className="fullScreenPopMessageContainer"
        onClick={clickOnEmptySpace}
        style={{ zIndex: props.zIndex + 1 }}
      >
        <div className="popUpContainer" id={"popUpContainer"}>
          <div className="popUpMessageContainer" id={"popUp3"}>
            <h2 className="popUpTitle" id={"popUp4"}>
              Vanliga frågor
            </h2>
            <p className="popUpParagraph bold" id={"popUp5"}>
              Hur skickar jag kostnadsräkningen till domstolen?
            </p>
            <p className="popUpParagraph" id={"popUp5"}>
              Enklast är att skicka den PDF som verktyget skapar med mejl till
              domstolen. Om domstolen behöver kostnadsräkningen direkt är det
              enklast att mejla den direkt till rättens protokollförare. Många
              domstolar uppskattar att kostnadsräkningen ges in digitalt.
              Kostnadsräkningen kan då bevaras digitalt vilket underlättar
              arkivhantering.
            </p>
            <p className="popUpParagraph" id={"popUp6"}>
              Om du redan har en kostnadsräkning på papper som du vill fylla i
              med bläckpenna vid slutet av förhandlingen så kan du använda
              verktyget för att räkna ut vilken ersättning du ska ska begära. På
              så viss undviker du felräkningar.
            </p>
            <p className="popUpParagraph bold" id={"popUp7"}>
              Hur gör jag om jag vill lägga till en arbetsredogörelse till
              kostnadsräkningen?
            </p>
            <p className="popUpParagraph" id={"popUp9"}>
              Förbered arbetsredogörelsen och skapa en PDF av den som du sen
              skickar in i samma mejl som kostnadsräkningen. Om du är
              intresserad av en version av verktyget där du även kan skapa
              arbetsredogörelser - skicka oss ett mejl och berätta om det!
            </p>
            <p className="popUpParagraph bold" id={"popUp10"}>
              Jag har underlag och kvitton för utlägg, hur lägger jag till
              dessa?
            </p>
            <p className="popUpParagraph" id={"popUp11"}>
              Smidigast är att skicka in dessa i PDF-format tillsammans med
              kostnadsräkningen, eller att lämna in dem direkt till domstolen
              vid förhandlingen.
            </p>
            <p className="popUpParagraph bold" id={"popUp12"}>
              Varför stämmer inte verktygets uträkning med min uträkning?
            </p>
            <p className="popUpParagraph" id={"popUp13"}>
              Domstolarna tillerkänner oftast (men inte alltid) ersättning för
              vardera av kategorierna arbete, tidsspillan, utlägg och
              mervärdesskatt i heltal. Det innebär att avrundning sker i varje
              enskild kategori. Verktyget har valt att följa denna modell för
              avrunding. Detta sätt att avrunda kan ibland medföra variationer i
              förhållande till det mer sedvanliga sättet att endast avrunda ören
              i sista ledet.
            </p>
            <p className="popUpParagraph bold" id={"popUp14"}>
              Hur använder jag QR-koden?{" "}
            </p>
            <p className="popUpParagraph" id={"popUp15"}>
              QR-koden på kostnadsräkningen innehåller en sträng med kod
              (URL-adress) med en anonymiserad version av alla informationsfält
              som går att fylla i. Genom att klicka på eller scanna QR-koden
              öppnas verktyget i en webbläsare. Därefter använder verktyget i
              webbläsaren koden för att återskapa informationen i
              informationsfälten lokalt på din dator.
            </p>
            <p className="popUpParagraph bold" id={"popUp16"}>
              Hur kan domstolen kontrollräkna?
            </p>
            <p className="popUpParagraph" id={"popUp16b"}>
              Domstolen kan klicka på QR-koden och länkas då till verktyget. Om
              domstolen vill tillerkänna ett lägre belopp än det begärda kan man
              kontrollräkna genom att reducera antalet timmar eller något annat
              värde.
            </p>
            <p className="popUpParagraph bold" id={"popUp17"}>
              Hur gör jag för att slippa fylla i information om bankgiro m.m.
              varje gång jag skapar en ny kostnadsräkning?
            </p>
            <p className="popUpParagraph" id={"popUp18"}>
              Genom att fylla i formuläret med de fasta uppgifterna
              (fakturainformationen) och därefter trycka på “Ladda ner PDF”, så
              skapas automatiskt en PDF som innehåller en QR-kod med en
              URL-adress som innehåller den information du fyllt i. Genom att
              använda QR-koden, eller skapa ett bokmärke i din webbläsare av
              URL:en kan du öppna verktyget med just dina förifyllda uppgifter.
              Därefter kan du fylla i de uppgifter du behöver. Glöm inte att
              fylla i fakturanummer, datum, domstolens målnummer och namn på den
              du företräder.
            </p>
            <p className="popUpParagraph bold" id={"popUp19"}>
              Är det säkert att använda verktyget?
            </p>
            <p className="popUpParagraph" id={"popUp20"}>
              När verktyget skapar PDF-filer och gör uträkningar så sker all
              hantering i användarens webbläsare. Detta medför att Domstools
              hemsida inte behandlar några personuppgifter. När en användare
              återskapar en kostnadsräkning genom att följa en QR-kod skickas
              information till Domstools. Denna information sparas eller
              behandlas inte av Domstools, utan används endast av verktyget i
              användarens webbläsare för att återskapa kostnadsräkningen.
            </p>
            <p className="popUpParagraph" id={"popUp21"}>
              När en användare följer URL:en i QR-koden så skulle
              informationsträngen kunna snappas upp när förfrågan skickas till
              Domstools. Eftersom URL-strängen inte innehåller några direkt
              läsbara uppgifter, så är det inte direkt känslig information i så
              fall som snappas upp.
            </p>
            <p className="popUpParagraph" id={"popUp22"}>
              Observera att namn på personer som omfattas av sekretess bör
              aldrig fyllas i direkt i verktyget. Användaren ansvarar själv för
              att de kostnadsräkningar som skapas behandlas i enlighet med
              gällande lagstiftning.
            </p>
            <p className="popUpParagraph bold" id={"popUp23"}>
              Kan någon obehörig få tillgång till QR-koden?
            </p>
            <p className="popUpParagraph" id={"popUp24"}>
              QR-koden innehåller indirekt all information som finns på
              kostnadsräkningen, men lagras endast i den PDF-fil som användaren
              skapar. Så länge QR-koden behandlas med samma varsamhet som
              kostnadsräkningen så är risken för obehörig åtkomst densamma som
              vid hanteringen av en vanlig kostnadsräkning.
            </p>
            <p className="popUpParagraph bold" id={"popUp25"}>
              Kan verktyget missbrukas så att obehöriga kan upprätta
              kostnadsräkningar?
            </p>
            <p className="popUpParagraph" id={"popUp26"}>
              Idag kan vem som helst använda verktyget för att skapa
              kostnadsräkningar. Detta skiljer sig inte mot att vanliga
              kostnadsräkningar kan skapas av den som har tillgång till ett
              ordbehandlingsprogram. En mottagare som vill vara säker på att en
              kostnadsräkning har rätt avsändare kan begära att
              kostnadsräkningen undertecknas fysiskt eller digitalt. Varje PDF
              som skapas av verktyget innehåller uppgift om när den skapades.
            </p>
            <p className="popUpParagraph bold" id={"popUp27"}>
              Jag skulle vilja lägga till en logga på kostnadsräkningen, kan ni
              ordna det?
            </p>
            <p className="popUpParagraph" id={"popUp28"}>
              Den möjligheten kan finnas i framtiden. Hör av dig till oss om det
              är intressant för dig!
            </p>
            <p className="popUpParagraph bold" id={"popUp29"}>
              Jag skulle vilja att verktyget sparade mina kostnadsräkningar, kan
              ni ordna det?
            </p>
            <p className="popUpParagraph" id={"popUp30"}>
              En stor fördel av verktyget är att all information som fylls i
              endast sparas i den PDF som laddas ner, och den QR-kod med URL som
              finns på kostnadsräkningen. Skulle verktyget själv spara
              information medför det flertalet överväganden med koppling till
              personuppgiftshantering. Om du är intresserad av en variant som
              sparar kostnadsräkningarna får du gärna höra av dig och berätta om
              det för oss. Men du kan givetvis välja att spara den skapade
              PDF:en på lämplig plats där du kommer åt den.
            </p>
            <p className="popUpParagraph bold" id={"popUp31"}>
              Varför har verktyget skapas?
            </p>
            <p className="popUpParagraph" id={"popUp32"}>
              En inte obetydlig del av domstolarnas tid går åt att kontrollräkna
              kostnadsräkningar och begära förtydliganden vad gäller
              felräkningar. Verktyget skapades för att på ett enkelt och säkert
              sätt bidra till att spara tid åt domstolarna och förhoppningsvis
              även förkorta den obekväma tystnad som uppstår när ombud under
              tidspress tvingas genomföra matematiska uträkningar vid slutet av
              en förhandling och eliminera rena räknefel (som inte är helt
              ovanliga).
            </p>
            <p className="popUpParagraph bold" id={"popUp33"}>
              Kostar verktyget något?
            </p>
            <p className="popUpParagraph" id={"popUp34"}>
              Just nu erbjuds verktyget fritt och utan kostnad.
            </p>
            <p className="popUpParagraph bold" id={"popUp35"}>
              Det vore väldigt smidigt om ni lade till [förslag på ny funktion],
              kan ni ordna det?{" "}
            </p>
            <p className="popUpParagraph" id={"popUp36"}>
              Det kanske vi kan! Hör av dig till oss och fråga!{" "}
            </p>
          </div>

          <div
            className="exitButtonContainer"
            onClick={props.cancelFunction}
            id={"popUp1"}
          >
            <img
              src={exitImg}
              alt="exitImg"
              className="exitButtonImg"
              id={"popUp2"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
