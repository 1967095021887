import { TextField } from "@mui/material";
import React from "react";
import { FakturaInformationInfo } from "../../../models/FakturaInformationInfo";

import { deepCloneObject } from "../../../services/commonFunctions";

type Props = {
  fakturaInformationInfo: FakturaInformationInfo;
  setFakturaInformationInfo: (param: FakturaInformationInfo) => void;
};

export default function FakturaInformation(props: Props) {
  function handleChangeValue(
    type: keyof FakturaInformationInfo,
    value: string
  ) {
    let tempFakturaInformationInfo = deepCloneObject(
      props.fakturaInformationInfo
    );
    tempFakturaInformationInfo[type] = value;
    props.setFakturaInformationInfo(tempFakturaInformationInfo);
  }

  return (
    <div className="sectionContainer">
      <div className="sectionTitleContainer">Fakturainformation</div>
      <div className="inputsContainer">
        <TextField
          label="Fakturanummer"
          value={props.fakturaInformationInfo.fakturaNummer}
          variant="standard"
          onChange={(e) => handleChangeValue("fakturaNummer", e.target.value)}
          className="input"
        />
        <TextField
          label="Advokat- eller juristbyrå"
          value={props.fakturaInformationInfo.advokatbyra}
          variant="standard"
          onChange={(e) => handleChangeValue("advokatbyra", e.target.value)}
          className="input"
        />
        <TextField
          label="Namn advokat/jurist"
          value={props.fakturaInformationInfo.advokatsNamn}
          variant="standard"
          onChange={(e) => handleChangeValue("advokatsNamn", e.target.value)}
          className="input"
        />
        <TextField
          label="Adress"
          value={props.fakturaInformationInfo.adress}
          variant="standard"
          onChange={(e) => handleChangeValue("adress", e.target.value)}
          className="input"
        />

        <TextField
          label="Postnummer"
          value={props.fakturaInformationInfo.postnummer}
          variant="standard"
          onChange={(e) => handleChangeValue("postnummer", e.target.value)}
          className="input"
        />
        <TextField
          label="Ort"
          value={props.fakturaInformationInfo.ort}
          variant="standard"
          onChange={(e) => handleChangeValue("ort", e.target.value)}
          className="input"
        />
        <TextField
          label="Telefon"
          value={props.fakturaInformationInfo.telefon}
          variant="standard"
          onChange={(e) => handleChangeValue("telefon", e.target.value)}
          className="input"
        />
        <TextField
          label="E-post"
          value={props.fakturaInformationInfo.epost}
          variant="standard"
          onChange={(e) => handleChangeValue("epost", e.target.value)}
          className="input"
        />
        <TextField
          label="Org.nummer"
          value={props.fakturaInformationInfo.orgNummer}
          variant="standard"
          onChange={(e) => handleChangeValue("orgNummer", e.target.value)}
          className="input"
        />
        <TextField
          label="Bankgiro"
          value={props.fakturaInformationInfo.bankgiro}
          variant="standard"
          onChange={(e) => handleChangeValue("bankgiro", e.target.value)}
          className="input"
        />
      </div>
    </div>
  );
}
