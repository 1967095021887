import { get } from "./requests";

export const getLawyerRatesByYear = async (year: number) => {
  try {
    const res = await get(
      `${process.env.REACT_APP_API_BASIC_URL}expenses/lawyer_rates/${year}/`,
      {}
    );
    return res.data;
  } catch (error: any) {
    if (error && error.response.status) {
      return 404;
    }

    return {};
  }
};
