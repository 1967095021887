import React from "react";
import { FakturaInformationInfo } from "../../../models/FakturaInformationInfo";
import { InformationOmMaletInfo } from "../../../models/InformationOmMaletInfo";
import FakturaInformation from "../invoiceInfoComponents/FakturaInformation";
import InformationOmMalet from "../invoiceInfoComponents/InformationOmMalet";
import lawImg from "../../../resources/images/law-document.svg";
import donwloadImg from "../../../resources/images/download-file_PDF.svg";

type Props = {
  fakturaInformationInfo: FakturaInformationInfo;
  setFakturaInformationInfo: (param: FakturaInformationInfo) => void;
  informationOmMaletInfo: InformationOmMaletInfo;
  setInformationOmMaletInfo: (param: InformationOmMaletInfo) => void;
  setCreateAndDownLoadPdf: (param: boolean) => void;
  setLoadingPdf: (param: boolean) => void;
  fakturaInformationMenu: boolean;
  setFakturaInformationMenu: (param: boolean) => void;
};

export default function IncvoiceInfo(props: Props) {
  return props.fakturaInformationMenu ? (
    <>
      <FakturaInformation
        fakturaInformationInfo={props.fakturaInformationInfo}
        setFakturaInformationInfo={props.setFakturaInformationInfo}
      />
      <InformationOmMalet
        informationOmMaletInfo={props.informationOmMaletInfo}
        setInformationOmMaletInfo={props.setInformationOmMaletInfo}
      />
      <div
        className="buttonContainer"
        onClick={() => props.setFakturaInformationMenu(true)}
      >
        <button
          className="basicButton"
          onClick={() => props.setLoadingPdf(true)}
        >
          <img src={donwloadImg} alt="lawImg" className="buttonImg" />
          <p className="buttonText">Ladda ner PDF</p>
        </button>
      </div>
    </>
  ) : (
    <div
      className="buttonContainer"
      onClick={() => props.setFakturaInformationMenu(true)}
    >
      <button className="basicButton">
        <img src={lawImg} alt="lawImg" className="buttonImg" />
        <p className="buttonText">Fakturainformation</p>
      </button>
    </div>
  );
}
