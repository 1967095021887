import React, { useEffect } from "react";
import Counter from "../../../components/Counter";
import { ArbetadTidInfo } from "../../../models/ArbetadTidInfo";
import {
  deepCloneObject,
  numToString,
  roundTo2Decimals,
} from "../../../services/commonFunctions";
import Checkbox from "@mui/material/Checkbox";
import { TidsspillanInfo } from "../../../models/TidsspillanInfo";
import { UtlaggInfo } from "../../../models/UtlaggInfo";

type Props = {
  arbetadTidInfo: ArbetadTidInfo;
  setArbetadTidInfo: (param: ArbetadTidInfo) => void;
  hourlyRate: number;
  helgdagHourlyRate: number;
  haktningsforhandlingRate: number;
  rpuRate: number;
  sumArbetadTid: number;
  setSumArbetadTid: (param: number) => void;
  ordinarieSum: number;
  setOrdinarieSum: (param: number) => void;
  helgdagSum: number;
  setHelgdagSum: (param: number) => void;
};

export default function ArbetadTid(props: Props) {
  useEffect(() => {
    if (props.arbetadTidInfo.taxemal) {
      let totalCost = props.hourlyRate;
      if (props.arbetadTidInfo.haktningsforhandling) {
        totalCost += props.haktningsforhandlingRate;
      }
      if (props.arbetadTidInfo.rpu) {
        totalCost += props.rpuRate;
      }
      props.setOrdinarieSum(0);
      props.setHelgdagSum(0);
      props.setSumArbetadTid(roundTo2Decimals(totalCost));
    } else {
      let totalOrdinarieHours =
        props.arbetadTidInfo.hours + props.arbetadTidInfo.minutes / 60;
      let totalHelgDagHours =
        props.arbetadTidInfo.helgdagHours +
        props.arbetadTidInfo.helgdagMinutes / 60;

      let tempOrdinarieCost = props.hourlyRate * totalOrdinarieHours;
      let tempHelgdagCost = props.helgdagHourlyRate * totalHelgDagHours;
      props.setOrdinarieSum(roundTo2Decimals(tempOrdinarieCost));
      props.setHelgdagSum(roundTo2Decimals(tempHelgdagCost));
      props.setSumArbetadTid(
        roundTo2Decimals(tempOrdinarieCost + tempHelgdagCost)
      );
    }

    // eslint-disable-next-line
  }, [props.arbetadTidInfo, props.hourlyRate]);

  function handleChangeValue(
    type: keyof TidsspillanInfo | keyof ArbetadTidInfo | keyof UtlaggInfo,
    value: number | boolean
  ) {
    let tempArbetadTidInfo = deepCloneObject(props.arbetadTidInfo);
    tempArbetadTidInfo[type] = value;
    if (type === "taxemal") {
      tempArbetadTidInfo.haktningsforhandling = false;
      tempArbetadTidInfo.rpu = false;
      tempArbetadTidInfo.helgdagHours = 0;
      tempArbetadTidInfo.helgdagMinutes = 0;
    }
    if (tempArbetadTidInfo.taxemal) {
      if (tempArbetadTidInfo.hours >= 3) {
        tempArbetadTidInfo.hours = 3;
        if (tempArbetadTidInfo.minutes >= 45) {
          tempArbetadTidInfo.minutes = 45;
        }
      }
    }
    props.setArbetadTidInfo(tempArbetadTidInfo);
  }
  return (
    <div className="sectionContainer">
      <div className="sectionTitleContainer">
        {props.arbetadTidInfo.taxemal ? "Förhandlingstid" : "Arbetad tid"}
      </div>
      {!props.arbetadTidInfo.taxemal ? (
        <>
          <div className="hoursAndMinutesContainer">
            <p className="hoursAndMinutesSubtitle">Ordinarie tid</p>
            <Counter
              title="Timmar"
              step={1}
              min={0}
              max={100}
              value={props.arbetadTidInfo.hours}
              type="hours"
              handleChangeValue={handleChangeValue}
            />
            <Counter
              title="Minuter"
              step={5}
              min={0}
              max={59}
              type="minutes"
              value={props.arbetadTidInfo.minutes}
              handleChangeValue={handleChangeValue}
            />
            <div className="timeCostContainer">
              <p className="timeCostText">
                (Timkostnad: <span>{numToString(props.hourlyRate)}</span> kr)
              </p>
            </div>
            <div className="hoursAndMinutesSumContainer">
              <p className="hoursAndMinutesSumText">
                Ersättning: {numToString(props.ordinarieSum)} Kr
              </p>
            </div>
          </div>
          <div className="hoursAndMinutesContainer">
            <p className="hoursAndMinutesSubtitle">
              Häktningsförhandling helgdag
            </p>
            <Counter
              title="Timmar"
              step={1}
              min={0}
              max={100}
              value={props.arbetadTidInfo.helgdagHours}
              type="helgdagHours"
              handleChangeValue={handleChangeValue}
            />
            <Counter
              title="Minuter"
              step={5}
              min={0}
              max={59}
              type="helgdagMinutes"
              value={props.arbetadTidInfo.helgdagMinutes}
              handleChangeValue={handleChangeValue}
            />
            <div className="timeCostContainer">
              <p className="timeCostText">
                (Timkostnad: <span>{numToString(props.helgdagHourlyRate)}</span>{" "}
                kr)
              </p>
            </div>
            <div className="hoursAndMinutesSumContainer">
              <p className="hoursAndMinutesSumText">
                Ersättning helg: {numToString(props.helgdagSum)} Kr
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="hoursAndMinutesContainer">
          <Counter
            title="Timmar"
            step={1}
            min={0}
            max={100}
            value={props.arbetadTidInfo.hours}
            type="hours"
            handleChangeValue={handleChangeValue}
          />
          <Counter
            title="Minuter"
            step={5}
            min={0}
            max={59}
            type="minutes"
            value={props.arbetadTidInfo.minutes}
            handleChangeValue={handleChangeValue}
          />
          <div className="timeCostContainer">
            <p className="timeCostText">
              Kostnad enligt taxa: {props.hourlyRate} kr
            </p>
          </div>
        </div>
      )}
      <div className="tickboxesContainer">
        <div className="optionContainer">
          <div className="singleTickBoxContainer">
            <Checkbox
              checked={props.arbetadTidInfo.taxemal}
              onChange={(e) => handleChangeValue("taxemal", e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <p className="tickLabel">Taxemål</p>
          </div>
        </div>
        {props.arbetadTidInfo.taxemal ? (
          <>
            <div className="optionContainer">
              <div className="singleTickBoxContainer">
                <Checkbox
                  checked={props.arbetadTidInfo.haktningsforhandling}
                  onChange={(e) =>
                    handleChangeValue("haktningsforhandling", e.target.checked)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
                <p className="tickLabel">Häktningsförhandling</p>
              </div>
              {props.arbetadTidInfo.haktningsforhandling ? (
                <div className="rateContainer">
                  <p className="rateText">
                    {numToString(props.haktningsforhandlingRate)} Kr
                  </p>
                </div>
              ) : null}
            </div>
            <div className="optionContainer">
              <div className="singleTickBoxContainer">
                <Checkbox
                  checked={props.arbetadTidInfo.rpu}
                  onChange={(e) => handleChangeValue("rpu", e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <p className="tickLabel">RPU</p>
              </div>
              {props.arbetadTidInfo.rpu ? (
                <div className="rateContainer">
                  <p className="rateText">{numToString(props.rpuRate)} Kr</p>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
      <div className="sumContainer">
        <p className="sumText">
          Summa arbete: {numToString(props.sumArbetadTid)} Kr
        </p>
        <p className="sumNumber"></p>
      </div>
    </div>
  );
}
